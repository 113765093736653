<h1 class="h2 header-title text-center margin-bottom">
  Set-up an Account on NOVA
</h1>

<div *ngIf="form" class="card">
  <ng-container [ngSwitch]="!!invited_email">
    
    <!-- DISPLAY SIGN UP FORM -->
    <ng-container *ngSwitchCase="false"> 
      <div class="card-title">
        <h2 class="h3">Sign up</h2>
        <div class="help-text">
          <p>Complete the required fields below.</p>
        </div>
      </div>
    
      <form [formGroup]="form">
        <div class="columns columns--2">
          <input-field labelText="First name">
            <input inputRef id="first_name_input" formControlName="first_name" required>
          </input-field>
      
          <input-field labelText="Last name">
            <input inputRef id="last_name_input" formControlName="last_name" required>
          </input-field>
        </div>
    
        <input-field labelText="Email">
          <input inputRef id="email_input" formControlName="email_address" type="email" required>
        </input-field>

        <div class="columns columns--2">
          <p>Already a NOVA user? <a (click)="routeToLogin()" class="text-link">Click here to login.</a></p>

          <div class="align-right">
            <button class="action-button action-button--primary margin-bottom-sm"
              [disabled]="shouldDisableSubmit()"
              (click)="sendInvitation()">
              Create Account
            </button>
            <po-status-message [statusMessage]="statusMessage"></po-status-message>
          </div>
        </div>
      </form>
    </ng-container>

    <!-- DISPLAY SUCCESS -->
    <ng-container *ngSwitchCase="true">
      <div class="card-title">
        <h2 class="h3">
          Success!
        </h2>
      </div>

      <p>A verification email has been sent to: <strong>{{invited_email}}</strong></p>
      <p>Please follow the link in your email to verify your email address and continue setting up your profile.</p>
    </ng-container>
  </ng-container>
</div>
