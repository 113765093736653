import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CHAR_LIMITS } from '@app/core/consts';

// Used for 2-column checklists
@Component({
  selector: 'radio-check-list',
  templateUrl: './radio-check-list.component.html'
})
export class RadioCheckListComponent {
  @Input() options: Array<string> = [];
  @Input() form: FormGroup;
  @Input() attribute: string;
  @Input() canEdit: boolean;
  @Output() checkChanged: EventEmitter<{ attribute: string, option: string }> = new EventEmitter();
  @Output() otherChanged: EventEmitter<string> = new EventEmitter();
  SPLIT_LIMIT: number = 6;  // The minimum list length required to split it into 2 columns
  OTHER_LENGTH: number = CHAR_LIMITS.SHORT;

  constructor() {}

  halfList(options: Array<string>, attribute: string, secondHalf?: boolean) {
    const list = options.filter(o => this.canEdit ? true : this.form.get(attribute)?.value[o]);
    const length = list.length;

    // Add 1 more to make the first half a little bigger to leave room for "Other" input in second half
    const halfIndex = length >= this.SPLIT_LIMIT ? Math.ceil(length / 2) + ((list.includes('Other') && length % 2 === 0) ? 1 : 0) : length;

    if (!secondHalf) {
      // First half
      return list.slice(0, halfIndex);
    }
    // Second half
    return list.slice(halfIndex, list.length);
  }

  isOtherChecked(attribute: string) {
    return () => {
      const controls = this.form.get(attribute);
      return controls && controls.get('Other')?.value;
    }
  }
}
