import { FUND_TYPES, PROGRAM_KEYS } from './core.const';

export const WILDCARD = '?';

export const AREAS = {
  ALL: 'all areas',
  ALL_PROGRAMS: 'all-programs',
  ALLOCATION: 'allocation',
  ALLOCATION_END_REPORT: 'allocation_end_report',
  ALLOCATION_YEAR: 'allocation_year',
  ANALYTICS: 'analytics',
  ANNUAL_FISCAL_REPORT: 'annual-fiscal-report',
  ANNUAL_PLAN: 'annual-plan',
  APPLICATION: 'application',
  APPLICATION_WINDOW: 'application_window',
  BUDGET: 'project/budget',
  CFAD: 'cfad',
  CONTACTS: 'project/contacts',
  CYCLE: 'cycle',
  DEFERRAL: 'deferral',
  DELEGATE: 'delegate',
  DESCRIPTION: 'project/description',
  FILES: 'project/files',
  FISCAL_REPORT: 'fiscal-report',
  FUND_SETTINGS: 'fund-settings',
  HEADCOUNT_CERTIFICATION: 'headcount-certification',
  INSTITUTION: 'institution',
  LMI: 'project/lmi',
  LOI: 'LOI',
  MID_ALLOCATION_REPORT: 'mid_allocation_report',
  OFFER: 'offer',
  OUTCOMES: 'project/outcomes',
  PLAN: 'plan',
  PREVIEW: 'project/preview',
  PROGRAM_ADMIN: 'program-admin',
  PROGRAM_AREA_REPORT: 'program-area-report',
  PROGRAM_SETTINGS: 'program_settings',
  PROJECT: 'project',
  RENEWAL: 'renewal',
  REVIEW: 'review',
  REVIEW_WINDOW: 'review_window',
  RSI_REPORT: 'rsi_report',
  SETTINGS_BY_YEAR: 'settings_by_year',
  SURVEY: 'survey',
  USER_ADMIN: 'user-admin',
  WORKPLAN: 'project/workplan',
  HEADCOUNT: 'headcount',
  WEP: 'wep'
};

export const ACTIONS = {
  ALL: 'all actions',
  VIEW: 'view',
  CREATE: 'create',
  DELETE: 'delete',
  EDIT: 'edit',
  SUBMIT: 'submit',
  CERTIFY: 'certify',
  APPROVE: 'approve',
  SUDO: 'sudo',
  CLOSE: 'close',
  REOPEN: 'reopen',
};

export const UNRESTRICTED = 'Unrestricted';

export const SYSTEM_ROLES = {
  SYSTEM_ADMIN: {
    ID: 1
  },
  NOVA_ADMIN: {
    ID: 17
  },
  INSTITUTION_FISCAL_REPORTER: {
    ID: 2
  },
  FUND_MANAGER: {
    ID: 11,
  },
  FUND_MONITOR: {
    ID: 36,
    ALIASES: {
      [PROGRAM_KEYS.NEP]: 'CCCCO Project Monitor',
      [PROGRAM_KEYS.CAI]: 'CCCCO Project Monitor'
    }
  },
  FUND_ANALYST: {
    ID: 37,
  },
  INSTITUTION_PROJECT_MANAGER: {
    ID: 18,
  },
  CERTIFYING_AUTHORITY: {
    ID: 19,
    ALIASES: {
      [FUND_TYPES.SWP_L]: 'Signing Authority',
      [FUND_TYPES.SWP_R]: 'Signing Authority',
      [FUND_TYPES.SWP_K12]: 'Regional Signing Authority',
      [PROGRAM_KEYS.SWP_L]: 'Signing Authority',
      [PROGRAM_KEYS.SWP_R]: 'Signing Authority',
      [PROGRAM_KEYS.SWP_K12]: 'Regional Signing Authority',
    }
  },
  INSTITUTION_MANAGER: {
    ID: 40,
  },
  INSTITUTION_PROJECT_REVIEWER: {
    ID: 41,
    ALIASES: {
      [FUND_TYPES.SWP_K12]: 'Selection Committee Member',
      [PROGRAM_KEYS.SWP_K12]: 'Selection Committee Member',
      [PROGRAM_KEYS.CAI]: 'Reviewer',
      [PROGRAM_KEYS.RFA]: 'Reviewer',
      [PROGRAM_KEYS.RFA_v2]: 'Reviewer',
    },
    RANGE: {
      MIN: 3,
    }
  },
  COMMITTEE_CHAIR: {
    ID: 43,
    ALIASES: {
      [FUND_TYPES.SWP_K12]: 'Selection Committee Chair',
      [PROGRAM_KEYS.SWP_K12]: 'Selection Committee Chair',
      [PROGRAM_KEYS.CAI]: 'Committee Chair',
      [PROGRAM_KEYS.RFA]: 'Committee Chair',
      [PROGRAM_KEYS.RFA_v2]: 'Committee Chair',
    }
  },
  SECTOR_FUND_MONITOR: {
    ID: 58,
  },
  REGIONAL_FUND_MONITOR: {
    ID: 52
  },
  DISTRICT_CHIEF_BUSINESS_OFFICER: {
    ID: 53
  },
  FUND_FISCAL_REPORT_APPROVER: {
    ID: 63,
  },
};

export const PROJECT_ROLES = {
  CONTACT: {
    ID: 20,
    ALIASES: {
      [FUND_TYPES.SWP_L]: 'Additional Contact',
      [PROGRAM_KEYS.SWP_L]: 'Additional Contact',

      [FUND_TYPES.SWP_R]: 'Additional Contact',
      [PROGRAM_KEYS.SWP_R]: 'Additional Contact',

      [FUND_TYPES.LVG]: 'Additional Contact',
      [PROGRAM_KEYS.LVG]: 'Additional Contact',
      ['DEFAULT']: 'Contact',
    }
  },
  PRIMARY_CONTACT: {
    ID: 21,
    ALIASES: {
      ['DEFAULT']: 'Primary Contact',
    },
  },
  INSTITUTION_PROJECT_FISCAL_REPORTER: {
    ID: 22,
    ALIASES: {
      [PROGRAM_KEYS.RCM]: 'Fiscal Reporter',
      ['DEFAULT']: 'Institution Project Fiscal Reporter',
    },
  },
  PROJECT_LEAD: {
    ID: 23,
    ALIASES: {
      [FUND_TYPES.GP]: 'Point of Contact',
      [PROGRAM_KEYS.GP]: 'Point of Contact',

      [PROGRAM_KEYS.GP_2]: 'Project Director',
      [PROGRAM_KEYS.EWD]: 'Project Director',

      [FUND_TYPES.IPLAN]: 'Project Lead Contact',
      [PROGRAM_KEYS.IPLAN]: 'Project Lead Contact',

      [FUND_TYPES.LVG]: 'Lead Contact',
      [PROGRAM_KEYS.LVG]: 'Lead Contact',

      [FUND_TYPES.SWP_K12]: 'Project Lead',
      [PROGRAM_KEYS.SWP_K12]: 'Project Lead',

      [PROGRAM_KEYS.SEP_V2]: 'Project Lead',
      [PROGRAM_KEYS.SEP_V3]: 'Project Lead',

      [PROGRAM_KEYS.CAI]: 'Project Lead Contact',
      [PROGRAM_KEYS.RFA]: 'Project Lead Contact',
      [PROGRAM_KEYS.RFA_v2]: 'Project Lead Contact',

      [PROGRAM_KEYS.PERKINS]: 'District Contact/Project Director',

      [PROGRAM_KEYS.RCM]: 'Project Director',

      ['DEFAULT']: 'Project Lead Contact'
    }
  },
  FISCAL_CONTACT: {
    ID: 24,
    ALIASES: {
      ['DEFAULT']: 'Fiscal Contact',
    },
  },
  MEMBER_REP: {
    ID: 25,
    ALIASES: {
      ['DEFAULT']: 'Member Representative',
    },
  },
  CERTIFYING_OFFICER: {
    ID: 26,
    ALIASES: {
      ['DEFAULT']: 'Certifying Officer',
    },
  },
  CONSORTIUM_CHAIR: {
    ID: 27,
    ALIASES: {
      ['DEFAULT']: 'Consortium Chair',
    },
  },
  CONSORTIUM_CO_CHAIR: {
    ID: 28,
    ALIASES: {
      ['DEFAULT']: 'Consortium Co-Chair',
    },
  },
  CONSORTIUM_CONTRIBUTOR: {
    ID: 29,
    ALIASES: {
      ['DEFAULT']: 'Consortium Contributor',
    },
  },
  CHIEF_INSTRUCTIONAL_OFFICER: {
    ID: 30,
    ALIASES: {
      ['DEFAULT']: 'Chief Instructional Officer',
    },
  },
  CHIEF_STUDENT_SERVICES_OFFICER: {
    ID: 31,
    ALIASES: {
      ['DEFAULT']: 'Chief Student Services Officer',
    },
  },
  CHANCELLOR_PRESIDENT: {
    ID: 32,
    ALIASES: {
      ['DEFAULT']: 'Chancellor/President',
    },
  },
  PRESIDENT_ACADEMIC_SENATE: {
    ID: 33,
    ALIASES: {
      ['DEFAULT']: 'Academic Senate President',
    },
  },
  ALTERNATE_PROJECT_LEAD: {
    ID: 34,
    ALIASES: {
      [FUND_TYPES.GP]: 'Alternate Point of Contact',
      [PROGRAM_KEYS.GP]: 'Alternate Point of Contact',

      [PROGRAM_KEYS.GP_2]: 'Alternate Project Contact',

      [FUND_TYPES.IPLAN]: 'Alternate Lead Contact',
      [PROGRAM_KEYS.IPLAN]: 'Alternate Lead Contact',

      [FUND_TYPES.SWP_K12]: 'Alternate Lead Contact',
      [PROGRAM_KEYS.SWP_K12]: 'Alternate Lead Contact',
      [PROGRAM_KEYS.SWP_K12_v4]: 'Project Lead Alternate Contact',
      [PROGRAM_KEYS.SWP_K12_v5]: 'Project Lead Alternate Contact',

      [PROGRAM_KEYS.SEP_V2]: 'Alternate Project Lead',
      [PROGRAM_KEYS.SEP_V3]: 'Alternate Project Lead',

      [PROGRAM_KEYS.CAI]: 'Project Contact',
      [PROGRAM_KEYS.RFA]: 'Project Contact',
      [PROGRAM_KEYS.RFA_v2]: 'Project Contact',

      [PROGRAM_KEYS.RCM]: 'Alternate Project Director',

      ['DEFAULT']: 'Alternate Project Lead Contact',
    }
  },
  CHIEF_BUSINESS_OFFICER: {
    ID: 35,
    ALIASES: {
      [PROGRAM_KEYS.NEP]: 'District Chief Business Officer',
      ['DEFAULT']: 'Chief Business Officer',
    }
  },
  TRUSTEES_BOARD_PRESIDENT: {
    ID: 38,
    ALIASES: {
      ['DEFAULT']: 'Board of Trustees President',
    },
  },
  CEO: {
    ID: 39,
    ALIASES: {
      ['DEFAULT']: 'Chief Executive Officer',
    },
  },
  PROJECT_REVIEWER: {
    ID: 42,
    ALIASES: {
      [PROGRAM_KEYS.EWD]: 'Responsible Administrator',
      ['DEFAULT']: 'Project Reviewer',
    },
  },
  RELATED_PERSON: {
    ID: 45,
    ALIASES: {
      ['DEFAULT']: 'Related Person',
    },
  },
  DISTRICT_SUP_PRES: {
    ID: 46,
    ALIASES: {
      ['DEFAULT']: 'District Superintendent/President',
    },
  },
  RESPONSIBLE_ADMIN: {
    ID: 47,
    ALIASES: {
      [PROGRAM_KEYS.PERKINS]: 'Responsible Administrator',
      ['DEFAULT']: 'Responsible Admin',
    },
  },
  RESPONSIBLE_PERSON: {
    ID: 48,
    ALIASES: {
      ['DEFAULT']: 'Responsible Person',
    },
  },
  RSI_REPORTER: {
    ID: 49,
    ALIASES: {
      ['DEFAULT']: 'RSI Reporter',
    },
  },
  VICE_CHANCELLOR: {
    ID: 50,
    ALIASES: {
      ['DEFAULT']: 'Vice Chancellor',
    }
  },
  FISCAL_AGENT_CERTIFIER: {
    ID: 51,
    ALIASES: {
      ['DEFAULT']: 'Fiscal Agent Certifier'
    }
  },
  COLLEGE_RESPONSIBLE_ADMIN: {
    ID: 54,
  },
  CONTACT_READ_ONLY: {
    ID: 55,
    ALIASES: {
      [PROGRAM_KEYS.PERKINS]: 'Additional Contact',
      [PROGRAM_KEYS.EWD]: 'Additional Contact',
      [PROGRAM_KEYS.RCM]: 'Alternate Contact',
      [PROGRAM_KEYS.SWP_K12_v4]: 'Project Viewer',
      [PROGRAM_KEYS.SWP_K12_v5]: 'Project Viewer',
      ['DEFAULT']: 'Contact'
    }
  },
  BUDGET_CERTIFIER: {
    ID: 56,
    ALIASES: {
      [PROGRAM_KEYS.PERKINS]: 'Person Responsible for Budget Certification',
      ['DEFAULT']: 'Budget Certifier'
    }
  },
  DISTRICT_RESPONSIBLE_ADMIN: {
    ID: 57,
    ALIASES: {
      ['DEFAULT']: 'District Responsible Admin',
    },
  },
  PROJECT_CONTRIBUTOR_REPORTER: {
    ID: 59,
    ALIASES: {
      ['DEFAULT']: 'Project Contributor & Reporter'
    }
  },
  FISCAL_APPROVER: {
    ID: 60
  },
  PLAN_APPROVER: { ID: 61 },
  FISCAL_REPORT_APPROVER: { ID: 62 },
  PROGRAM_ADMIN: { ID: 64 },
  PROGRAM_AUDITOR: { ID: 65 },
  PROJECT_LEAD_ALTERNATE: {
    ID: 66,
    ALIASES: {
      [PROGRAM_KEYS.SWP_K12]: 'Project Lead Alternate Contact',
      ['DEFAULT']: 'Project Lead Alternate Contact',
    }
  },
  PROJECT_EDITOR: {
    ID: 67,
    ALIASES: {
      ['DEFAULT']: 'Project Editor',
    }
  },
  GP_COORDINATOR: {
    ID: 69,
    ALIASES: {
      ['DEFAULT']: 'Guided Pathways Coordinator/Lead'
    }
  }
};

export const PROTECTED_ROLE_IDS = [
  SYSTEM_ROLES.INSTITUTION_PROJECT_REVIEWER.ID,
  SYSTEM_ROLES.COMMITTEE_CHAIR.ID,
];

export const ROLES_BY_PROGRAM: { [key: string]: Array<number> } = {
  [PROGRAM_KEYS.CAEP]: [
    PROJECT_ROLES.PRIMARY_CONTACT.ID,
    PROJECT_ROLES.FISCAL_CONTACT.ID,
    PROJECT_ROLES.MEMBER_REP.ID,
    PROJECT_ROLES.CERTIFYING_OFFICER.ID,
    PROJECT_ROLES.CONSORTIUM_CHAIR.ID,
    PROJECT_ROLES.CONSORTIUM_CO_CHAIR.ID,
    PROJECT_ROLES.CONSORTIUM_CONTRIBUTOR.ID,
    PROJECT_ROLES.INSTITUTION_PROJECT_FISCAL_REPORTER.ID,
    PROJECT_ROLES.CONTACT.ID,
  ],
  [PROGRAM_KEYS.CAI]: [
    SYSTEM_ROLES.INSTITUTION_PROJECT_MANAGER.ID,
    PROJECT_ROLES.CONTACT.ID,
    PROJECT_ROLES.PROJECT_LEAD.ID,
    PROJECT_ROLES.ALTERNATE_PROJECT_LEAD.ID,
    PROJECT_ROLES.CHIEF_BUSINESS_OFFICER.ID,
    PROJECT_ROLES.RSI_REPORTER.ID
  ],
  [PROGRAM_KEYS.EWD]: [
    SYSTEM_ROLES.SECTOR_FUND_MONITOR.ID,
    PROJECT_ROLES.BUDGET_CERTIFIER.ID,
    SYSTEM_ROLES.DISTRICT_CHIEF_BUSINESS_OFFICER.ID,
    SYSTEM_ROLES.INSTITUTION_PROJECT_MANAGER.ID,
    PROJECT_ROLES.INSTITUTION_PROJECT_FISCAL_REPORTER.ID,
    PROJECT_ROLES.PROJECT_LEAD.ID,
    PROJECT_ROLES.CONTACT_READ_ONLY.ID,
    PROJECT_ROLES.BUDGET_CERTIFIER.ID,
  ],
  [PROGRAM_KEYS.GP]: [
    PROJECT_ROLES.PROJECT_LEAD.ID,
    PROJECT_ROLES.CHANCELLOR_PRESIDENT.ID,
    PROJECT_ROLES.PRESIDENT_ACADEMIC_SENATE.ID,
    PROJECT_ROLES.ALTERNATE_PROJECT_LEAD.ID,
    SYSTEM_ROLES.FUND_FISCAL_REPORT_APPROVER.ID,
    PROJECT_ROLES.PROJECT_EDITOR.ID,
  ],
  [PROGRAM_KEYS.IPLAN]: [
    PROJECT_ROLES.PROJECT_LEAD.ID,
    PROJECT_ROLES.CHIEF_INSTRUCTIONAL_OFFICER.ID,
    PROJECT_ROLES.CHANCELLOR_PRESIDENT.ID,
    PROJECT_ROLES.PRESIDENT_ACADEMIC_SENATE.ID,
    PROJECT_ROLES.ALTERNATE_PROJECT_LEAD.ID,
    PROJECT_ROLES.CHIEF_BUSINESS_OFFICER.ID
  ],
  [PROGRAM_KEYS.LVG]: [
    PROJECT_ROLES.CONTACT.ID,
    PROJECT_ROLES.PROJECT_LEAD.ID,
    PROJECT_ROLES.ALTERNATE_PROJECT_LEAD.ID,
    PROJECT_ROLES.PRESIDENT_ACADEMIC_SENATE.ID,
    PROJECT_ROLES.TRUSTEES_BOARD_PRESIDENT.ID,
    PROJECT_ROLES.CEO.ID
  ],
  [PROGRAM_KEYS.NEP]: [
    PROJECT_ROLES.INSTITUTION_PROJECT_FISCAL_REPORTER.ID,
    PROJECT_ROLES.PROJECT_LEAD.ID,
    PROJECT_ROLES.ALTERNATE_PROJECT_LEAD.ID,
    PROJECT_ROLES.CHIEF_BUSINESS_OFFICER.ID,
    PROJECT_ROLES.CEO.ID,
    PROJECT_ROLES.DISTRICT_SUP_PRES.ID,
    PROJECT_ROLES.RESPONSIBLE_ADMIN.ID,
    PROJECT_ROLES.RESPONSIBLE_PERSON.ID,
    SYSTEM_ROLES.REGIONAL_FUND_MONITOR.ID,
    SYSTEM_ROLES.DISTRICT_CHIEF_BUSINESS_OFFICER.ID,
    SYSTEM_ROLES.FUND_FISCAL_REPORT_APPROVER.ID
  ],
  [PROGRAM_KEYS.PERKINS]: [
    SYSTEM_ROLES.REGIONAL_FUND_MONITOR.ID,
    SYSTEM_ROLES.DISTRICT_CHIEF_BUSINESS_OFFICER.ID,
    PROJECT_ROLES.DISTRICT_RESPONSIBLE_ADMIN.ID,
    PROJECT_ROLES.COLLEGE_RESPONSIBLE_ADMIN.ID,
    PROJECT_ROLES.INSTITUTION_PROJECT_FISCAL_REPORTER.ID,
    PROJECT_ROLES.PROJECT_LEAD.ID,
    PROJECT_ROLES.DISTRICT_SUP_PRES.ID,
  ],
  [PROGRAM_KEYS.SWP_K12]: [
    SYSTEM_ROLES.REGIONAL_FUND_MONITOR.ID,
    SYSTEM_ROLES.DISTRICT_CHIEF_BUSINESS_OFFICER.ID,
    PROJECT_ROLES.PROJECT_LEAD.ID,
    PROJECT_ROLES.INSTITUTION_PROJECT_FISCAL_REPORTER.ID,
    PROJECT_ROLES.PROJECT_CONTRIBUTOR_REPORTER.ID,
    PROJECT_ROLES.PROJECT_LEAD_ALTERNATE.ID,
    SYSTEM_ROLES.COMMITTEE_CHAIR.ID,
    SYSTEM_ROLES.INSTITUTION_PROJECT_REVIEWER.ID,
    PROJECT_ROLES.PROJECT_REVIEWER.ID
  ],
  [PROGRAM_KEYS.RCM]: [
    SYSTEM_ROLES.DISTRICT_CHIEF_BUSINESS_OFFICER.ID,
    PROJECT_ROLES.PROJECT_LEAD.ID,
    PROJECT_ROLES.INSTITUTION_PROJECT_FISCAL_REPORTER.ID,
    PROJECT_ROLES.ALTERNATE_PROJECT_LEAD.ID,
    PROJECT_ROLES.CONTACT_READ_ONLY.ID
  ],
  [PROGRAM_KEYS.SEP]: [
    SYSTEM_ROLES.REGIONAL_FUND_MONITOR.ID,
    SYSTEM_ROLES.DISTRICT_CHIEF_BUSINESS_OFFICER.ID,
    SYSTEM_ROLES.FUND_FISCAL_REPORT_APPROVER.ID,
    PROJECT_ROLES.INSTITUTION_PROJECT_FISCAL_REPORTER.ID,
    PROJECT_ROLES.PROJECT_LEAD.ID,
    PROJECT_ROLES.CHIEF_INSTRUCTIONAL_OFFICER.ID,
    PROJECT_ROLES.CHIEF_STUDENT_SERVICES_OFFICER.ID,
    PROJECT_ROLES.CHANCELLOR_PRESIDENT.ID,
    PROJECT_ROLES.PRESIDENT_ACADEMIC_SENATE.ID,
    PROJECT_ROLES.ALTERNATE_PROJECT_LEAD.ID,
    PROJECT_ROLES.CHIEF_BUSINESS_OFFICER.ID,
    PROJECT_ROLES.RESPONSIBLE_PERSON.ID
  ],
  [PROGRAM_KEYS.SWP_L]: [
    PROJECT_ROLES.CONTACT.ID,
    PROJECT_ROLES.INSTITUTION_PROJECT_FISCAL_REPORTER.ID,
    PROJECT_ROLES.PROJECT_LEAD.ID,
    PROJECT_ROLES.RESPONSIBLE_PERSON.ID
  ],
  [PROGRAM_KEYS.SWP_R]: [
    PROJECT_ROLES.CONTACT.ID,
    PROJECT_ROLES.PROJECT_LEAD.ID,
    PROJECT_ROLES.FISCAL_APPROVER.ID,
    PROJECT_ROLES.INSTITUTION_PROJECT_FISCAL_REPORTER.ID,
    PROJECT_ROLES.RESPONSIBLE_PERSON.ID
  ]
};
