import { of } from 'rxjs';
import { take } from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { State, Queries, Actions, Model } from '@app-ngrx-domains';
import { Router, ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ValidatorsEx, UrlUtils } from '../../utilities';
import { ApiService } from '../../services';
import { PAGE_TYPES, ROUTER_LINKS, TASK_TYPES, WELCOME_MESSAGE } from '../../consts';
import { Profile, EnumErrorTypes } from '../../models';
import { environment } from 'environments/environment';


@Component({
  selector: 'app-register-profile',
  templateUrl: './register-profile.component.html'
})
export class RegisterProfileComponent implements OnInit, OnDestroy {
  form: FormGroup;
  user: Model.User;
  new_user_task_type = TASK_TYPES.INVITE_NEW_USER;
  new_user_task: Model.Task;
  headingText = 'Welcome';
  passwordSet = false;
  statusMessage: Model.StatusMessage = {
    message: undefined,
    type: undefined
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private store: Store<State>,
    private _fb: FormBuilder,
    private apiService: ApiService
  ) {
    this.store.dispatch(Actions.Layout.setPageType(PAGE_TYPES.NAVLESS));
  }

  ngOnInit() {
    if (environment.name !== 'demo') {
      this.headingText = WELCOME_MESSAGE;
    }

    // Verify the user is inactive, we do not want to allow changing profile info for an active user
    this.store.select(Queries.Auth.getCurrentUser)
      .pipe(take(1))
      .subscribe(user => {
        if (user.status !== 'inactive') {
          // This user has already set up their account, redirect to login page
          this.router.navigate([ROUTER_LINKS.LOGIN]);
        } else if (user) {
          this.user = user;
          this.new_user_task = this.user.tasks.find(task => task.task_type === this.new_user_task_type);
          this.initForm();
        }
      });
  }

  initForm() {
    this.form = this._fb.group({
      passwords: this._fb.group({
        password: ['', [ValidatorsEx.password]],
        confirm_password: ['', [ValidatorsEx.password]],
      }, {
        validator: ValidatorsEx.passwords('password', 'confirm_password')
      })
    });
  }

  setPassword() {
    const formValue = this.form.get('passwords').value;
    const password = {
      update: formValue.password,
      confirmation: formValue.confirm_password
    };

    this.statusMessage.message = 'Saving password';
    this.statusMessage.type = 'loading';
    this.apiService.updatePassword({ user_id: this.user.id, password }).subscribe(
      (res) => {
        this.passwordSet = true;
        this.statusMessage.type = undefined;
        // Re-authenticate with the password to obtain a real token
        this.store.dispatch(Actions.Auth.login(this.user.email_address, formValue.password));
      },
      (err) => {
        this.statusMessage.type = 'fail';
        if (err.status < 500 && err.error.message) {
          // Client Error
          this.statusMessage.message = err.error.message;
        } else {
          // Service Error
          this.statusMessage.message = 'There was a problem setting your password. Please try again.';
          this.store.dispatch(Actions.App.setError({
            type: EnumErrorTypes.api,
            location: this.constructor.name,
            show: false,
            raw: err
          }));
        }
      }
    );
  }

  profileSaved(profile) {
    /* Complete the sign-up task first because new tasks might be created on completion
      this way, we can pick up the new tasks when we save the user's profile */
    this.completeTask().subscribe(() => {
      this.store.dispatch(Actions.Auth.updateUser(profile));
      this.store.dispatch(Actions.Auth.login());

      this.store.select(Queries.Auth.getCurrentUser).subscribe(() => {
        // Redirect user to the url specified in their task
        const task_store = this.new_user_task ? this.new_user_task.store : {};
        const redirectUrl = task_store['redirect_url'] || ROUTER_LINKS.LOGIN;
        if (UrlUtils.isExternalUrl(redirectUrl)) {
          window.location.href = redirectUrl;
        } else {
          this.router.navigate([redirectUrl]);
        }
      });
    });
  }

  completeTask() {
    // Either complete the new_user task, or return nothing
    return (this.new_user_task && !this.new_user_task.completed
      ? this.apiService.completeTask({ task_type: this.new_user_task.task_type, user_id: this.user.id })
      : of(null));
  }

  shouldDisablePasswordSubmit() {
    return this.form.invalid || this.statusMessage.type === 'loading';
  }

  ngOnDestroy() {
    this.store.dispatch(Actions.Layout.clearPageType());
  }
}
