import { Directive, ElementRef, HostBinding, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Queries, State } from '@app-ngrx-domains';
import { CommentService } from '@app/core/services';

@Directive({
  selector: '[elementComment]'
})
export class ElementCommentDirective implements OnDestroy, OnInit {
  private destroy$: Subject<boolean> = new Subject();

  constructor(
    private el: ElementRef,
    private store: Store<State>,
    private commentService: CommentService,
  ) { }

  @Input('elementComment')
  @HostBinding('tabindex')

  tabindex: number;
  element: HTMLInputElement;
  isCommentsOpen = false;

  ngOnDestroy() {
    this.commentService.unregisterInputField(this.element.id);
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  ngOnInit() {
    this.element = this.el.nativeElement;
    this.tabindex = this.el.nativeElement.getAttribute('tabindex') ?? 0;
    if (!this.element.id) {
      console.error('elements require an ID for commenting');
    }

    this.commentService.registerInputField(this.element.id);

    // Listen for comments open
    this.store.select(Queries.Layout.isCommentsOpen)
      .pipe(takeUntil(this.destroy$))
      .subscribe(isOpen => {
        this.isCommentsOpen = isOpen;
      });
  }

  // listening for focus, instead of focusin for this directive allows the user to click on other elements
  // such as action buttons in a table row that has the elementComment applied to the entire row
  @HostListener('focus')
  handleFocus() {
    if (this.element.id && this.isCommentsOpen) {
      this.commentService.setShowCommentWidget({ id: this.element.id });
    }
  }

  @HostListener('focusout', ['$event'])
  handleFocusOut(event) {
    if (this.element.id && this.isCommentsOpen) {
      this.commentService.setHideCommentWidget({ id: this.element.id, event });
    }
  }
}
