<full-page-modal
  small="true"
  heading="Close Plan"
  [button-action]="'Submit'"
  [button-action-disabled]="closeProposalForm.invalid"
  (action)="submitCloseProposalForm()"
  [button-cancel]="'Cancel'"
  (cancel)="cancelCloseProposalForm()"
  (dismiss)="cancelCloseProposalForm()">
  <form [formGroup]="closeProposalForm" autocomplete="off" class="first-touch">
    <inline-alert level="warning" [noIcon]="true">
      <p>
        By clicking "Submit", you are confirming that you would like to close this plan. Once the closure is approved,
        no further updates to any plan related data will be allowed including budget adjustments and reporting.
        Be certain that budgets are correct and all fiscal reporting is submitted and approved before submitting this request.
      </p>
    </inline-alert>

    <div class="columns columns--2 no-margin">
      <input-group headingText="Closure Type" [required]="true">
        <radio-check labelText="Plan Completed">
          <input inputRef id="qa_close_modal_finished_radio" type="radio" name="plan_completed" [value]="true" formControlName="plan_completed">
        </radio-check>
        <radio-check labelText="Plan Not Completed">
          <input inputRef id="qa_close_modal_unpursued_radio" type="radio" name="plan_completed" [value]="false" formControlName="plan_completed">
        </radio-check>
      </input-group>

      <input-group headingText="Funds Expended" [required]="true">
        <radio-check labelText="Funds have been fully expended">
          <input inputRef id="qa_close_modal_expended_radio" type="radio" name="funds_expended" [value]="true" formControlName="funds_expended">
        </radio-check>
        <radio-check labelText="Funds have not been fully expended">
          <input inputRef id="qa_close_modal_unexpended_radio" type="radio" name="funds_expended" [value]="false" formControlName="funds_expended">
        </radio-check>
      </input-group>
    </div>

    <div>
      <input-field labelText="Funds Remaining" [showCounter]="true" *ngIf="require_funds_text">
        <div class="help-text">
          <p>Please describe what will be done with the remaining unspent funds.</p>
        </div>
        <textarea
          inputRef
          id="qa_close_modal_unspent_funds_text"
          formControlName="funds_outcomes"
          name="funding_outcomes"
          class="c-textarea__text"
          maxlength="500"
          aria-required="true">
        </textarea>
      </input-field>

      <input-field labelText="Outcome Summary" [showCounter]="true">
        <div class="help-text">
          <p>Please summarize the outcomes of this plan. If the plan was not complete, please also describe why you are no longer pursuing this plan.</p>
        </div>
        <textarea
          inputRef
          id="qa_close_modal_outcomes_text"
          formControlName="project_outcomes"
          name="project_outcomes"
          class="c-textarea__text"
          maxlength="3000"
          aria-required="true">
        </textarea>
      </input-field>

      <po-date-picker labelText="Actual Plan End Date"
        formControlName="close_date"
        [minDateString]="reportingStartDate"
        [maxDateString]="reportingEndDate"
        [canEdit]="true"
        [required]="true">
        <div class="help-text">
          <p>
            The closure date must be within the projected life time of the plan.
            Please enter the date to be within <strong>{{reportingStartDateFormatted}}</strong> and <strong>{{reportingEndDateFormatted}}</strong>.
          </p>
        </div>
      </po-date-picker>
    </div>
  </form>
</full-page-modal>
