<div class="columns columns--2 margin-bottom-none" [formGroup]="form.get(attribute)">
  <div *ngFor="let index of [0, 1]">
    <ng-container *ngFor="let option of halfList(options, attribute, !!index)">
      <radio-check [labelText]="option"
        [ngClass]="{ 'margin-bottom-none': option === 'Other' && isOtherChecked(attribute)() }"
        [canEdit]="canEdit" [readOnlyType]="'check'">
        <input id="{{attribute}}_chk_{{option}}" inputRef type="checkbox" class="checkbox"
          [formControlName]="option" (change)="checkChanged.emit({ attribute, option })"/>
      </radio-check>
      <input-field *ngIf="option === 'Other' && isOtherChecked(attribute)()" labelText="Other" [labelHidden]="true" [canEdit]="canEdit" class="margin-left-sm margin-top-xs">
        <input inputRef id="other_{{attribute}}_input" type="text" [formControl]="form.get(attribute + '_other')" [maxlength]="OTHER_LENGTH"
          placeholder='Specify "Other"' (change)="otherChanged.emit(attribute + '_other')" required aria-required="true"/>
      </input-field>
    </ng-container>
  </div>
</div>
