import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { COMMENT_ID_CLASSES } from '../consts';


@Injectable({ providedIn: 'root' })
export class CommentService {
  newCommentThreadData = new BehaviorSubject<any>(undefined);
  textAreaCommentClick = new Subject<string>();
  textAreaCommentData = new Subject<any>();
  showCommentWidget = new BehaviorSubject<any>(undefined);
  hideCommentWidget = new BehaviorSubject<any>(undefined);
  scrollToCommentId = new BehaviorSubject<number>(undefined);

  commentableFields: Array<string> = [];
  hasCommentableFields = new BehaviorSubject<boolean>(false);

  constructor() { };

  clearRegisteredInputs() {
    this.commentableFields = [];
    this.hasCommentableFields.next(false);
  }

  registerInputField(inputId: string) {
    if (inputId) {
      this.commentableFields.push(inputId);
    }

    if (this.commentableFields.length && !this.hasCommentableFields.value) {
      this.hasCommentableFields.next(true);
    }
  }

  unregisterInputField(inputId: string) {
    this.commentableFields = this.commentableFields.filter(id => id !== inputId);

    if (!this.commentableFields.length && this.hasCommentableFields.value) {
      this.hasCommentableFields.next(false);
    }
  }

  /**
   * @param inputElementId
   * @returns
   */
  getCommentWidgetElementId(inputElementId: string) {
    return `${inputElementId}_comment_action`.replace(/ /g, '_');
  }

  /**
   * returns either the prefix for a comment temp ID class name
   * or the classname specific to a temp ID (now in milliseconds)
   * this class should be short lived, and only exist until a comment is saved as a draft
   *
   * @param userId
   */
    getTempIdClass(tempID: number|string = ''): string {
      return `${COMMENT_ID_CLASSES.TEMP_ID}--${tempID}`;
    }

  /**
   * returns either the prefix for a comment highlight creator ID class
   * or the class specific to a user ID
   * used to create highlights for draft comments that are only visible to the creator
   * highlights are not specific to an element, just a user
   *
   * @param userId
   */
  getDraftHighlightClass(userId: number|string = ''): string {
    return `${COMMENT_ID_CLASSES.HIGHLIGHT_CREATOR_ID}--${userId}`;
  }

  /**
   * returns either the prefix for a comment create ID class
   * or the class specific to a user ID
   *
   * @param userId
   */
  getCommentCreatorIdClass(userId: number|string = ''): string {
    return `${COMMENT_ID_CLASSES.CREATOR_ID}--${userId}`;
  }

  /**
   * returns either the prefix for a comment create ID class
   * or the class specific to a comment ID
   *
   * @param commentId
   */
  getCommentIdClass(commentId: number | string = ''): string {
    return `${COMMENT_ID_CLASSES.COMMENT_ID}--${commentId}`;
  }

  /**
   * used by the comment widget to inform a froala editor that a comment
   * should be created for selected text
   * @param inputElementId
   */
  setTextAreaCommentClick(inputElementId: string) {
    this.textAreaCommentClick.next(inputElementId);
  }

  /**
   * used to pass comment data from a poTexArea to the comment widget
   * the comment widget can add an institution ID from a fiscal report page
   * that the text area can't see, but the comment widget can see
   *
   * @param element_id
   * @param tempId
   * @param comment
   * @param classes
   * @param institution_id
   */
  setTextAreaCommentData(element_id: string, callback: (commentId) => void) {
    const newCommentData = {
      element_id: element_id,
      callback
    };

    this.textAreaCommentData.next(newCommentData);
  }
  // setTextAreaCommentData(element_id: string, tempId = Date.now(), comment = '', classes: any = {}, institution_id: number = undefined) {
  //   if (this.isCommentingAvailable.getValue()) {
  //     const newCommentData = {
  //       type: 'comment',
  //       draft: true,
  //       deleted: false,
  //       resolved: false,
  //       creator_id: undefined,
  //       comment: comment,
  //       classes: classes,
  //       temp_id: tempId,
  //       element_id: element_id,
  //       institution_id: institution_id,
  //     };

  //     this.textAreaCommentData.next(newCommentData);
  //   }
  // }

  /**
   *
   * @param newCommentData
   */
  setNewCommentThreadData(newCommentData: any) {
    this.newCommentThreadData.next(newCommentData);
  }

  /**
   *
   * @param elementInfo
   */
  setShowCommentWidget(elementInfo: any|undefined) {
    this.showCommentWidget.next(elementInfo);
  }

  /**
   *
   * @param elementInfo
   */
  setHideCommentWidget(elementInfo: any|undefined) {
    this.hideCommentWidget.next(elementInfo);
  }

  /**
   * Froala editor uses this to scroll to an existing comment if a user clicks on a existing higlight
   *
   * @param commentId
   */
  setScrollToCommentId(commentId: number|undefined) {
    if (commentId) {
      this.scrollToCommentId.next(commentId);
    }
  }

  /**
   *
   * @param element_id
   * @param tempId
   * @param comment
   * @param classes
   * @param institution_id
   */
  // openNewCommentThread(element_id: string, tempId = Date.now(), comment = '', classes: any = {}, institution_id: number = undefined) {
  //   if (this.isCommentingAvailable.getValue()) {
  //     const newCommentData = {
  //       type: 'comment',
  //       draft: true,
  //       deleted: false,
  //       resolved: false,
  //       creator_id: undefined,
  //       comment: comment,
  //       classes: classes,
  //       // temp_id: tempId,
  //       element_id: element_id,
  //       institution_id: institution_id,
  //     };

  //     this.setNewCommentThreadData(newCommentData);
  //   }
  // }
  openNewCommentThread(element_id: string, institution_id: number = undefined, isTemp: boolean = true, callback?: () => {}) {
    const newCommentData = {
      type: 'comment',
      draft: true,
      element_id: element_id,
      institution_id: institution_id,
      isTemp,
      callback
    };

    this.setNewCommentThreadData(newCommentData);
  }

}
