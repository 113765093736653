<div class="columns columns--2 no-print margin-top">
  <h2 class="header-title h2 margin-bottom-none" id="qa_tab_title">Preview & Submit</h2>
  <div class="card-footer card-footer--right">
    <a *ngIf="supportsFiscalReporting && canShowFiscalReporting" [routerLink]="fiscalReportingLink"
      (click)="ngOnDestroy()"
      class="action-button action-button--secondary action-button--small margin-right-sm">
      Fiscal Reporting
    </a>
    <a *ngIf="!hideHistory" routerLink="../history" class="action-button action-button--secondary action-button--small icon--left">
      <icon iconId="history"></icon>
      Application History
    </a>
  </div>
</div>
<po-guidance-text *ngIf="guidance" [workflowFilter]="guidance"></po-guidance-text>

<!-- Plan Close Card -->
<ng-container *ngIf="!!closeContext && (isCloseRequested || isClosed)" [ngTemplateOutlet]="planCloseSection"></ng-container>

<!-- Preview Cards -->
<ng-container *ngIf="initialized" [ngTemplateOutlet]="previewSection"></ng-container>

<!-- Approval card -->
<div class="card" *ngIf="approvalTasks?.length">
  <div class="card-title">
    <h3 class="h3 mb-l">Certification</h3>
  </div>
  <ng-container>
    <div class="certification-card-grid-container" *ngIf="approvers && approvers.length; else loadingApprovers">
      <app-approval-card-v2 *ngFor="let approver of approvers"
        [headerText]="approver.name"
        [users]="approver.users"
        [task]="approver.task"
        [allowed]="allowApproval(approver.users)"
        [hideReminder]="!isSubmitted || !approver.users?.length"
        [disableActions]="disableApproval(approver.task)"
        (approve)="approve(approver.task)"
        (reject)="openRejectModal(approver.task)">
      </app-approval-card-v2>
    </div>
  </ng-container>
</div>

<!-- Email -->
<app-share-document
  [emailOptions]="emailOptions"
  sharePdfName="share">
</app-share-document>

<!-- Submit Alert -->
<alert *ngIf="showSubmitAlert"
  button-action="Submit"
  button-dismiss="Cancel"
  level="warning"
  (action)="submit()"
  (dismiss)="closeSubmitAlert()">
  <p>
    By clicking "Submit" you are submitting this plan for review. While in the "Submitted" state, no changes will be permitted.
  </p>
</alert>

<!-- Unsubmit Alert -->
<alert *ngIf="showUnsubmitAlert"
  button-action="Decertify"
  button-dismiss="Cancel"
  level="warning"
  (action)="unsubmit()"
  (dismiss)="toggleUnsubmitAlert()">
  <p [innerText]="unsubmitText"></p>
</alert>

<!-- Rejection Modal -->
<full-page-modal *ngIf="showRejectModal"
  [small]="true"
  heading="Reject {{planName}} {{isCloseRequested ? 'Close ' : ''}}Plan"
  button-action="Reject"
  button-cancel="Cancel"
  (action)="reject()"
  (dismiss)="closeRejectModal()"
  (cancel)="closeRejectModal()">
  <form [formGroup]="rejectProposalForm">
    <div class="mb-s">
      <ng-container [ngSwitch]="isCloseRequested">
        <p *ngSwitchCase="false">By clicking "Reject", you are confirming that you are rejecting this plan in its current state.</p>
        <p *ngSwitchCase="true">By clicking "Reject", you are confirming that you are rejecting the closure of this plan; the plan will remain open, and no changes will take place.</p>
      </ng-container>
    </div>
    <input-field labelText="Comments">
      <div class="help-text">
        <p>Please enter any comments for the plan owners to review.</p>
      </div>
      <po-html-textarea inputRef id="preview_comments_input"
        formControlName="comments"
        [htmlCharLimit]="commentsLength">
      </po-html-textarea>
    </input-field>
  </form>
</full-page-modal>

<!-- Loading Approver Contacts template -->
<ng-template #loadingApprovers>
  <ng-container [ngSwitch]="!!approvers">
    <p *ngSwitchCase="false" class="message">Loading approvers... <span class="loader-spinner-inline"></span></p>
    <p *ngSwitchCase="true" class="empty-state">
      No approvers authorities have been assigned.
    </p>
  </ng-container>
</ng-template>

<!-- Closure Request Modal -->
<close-project-modal *ngIf="showCloseProposalModal"
  [programKey]="programKey"
  [projectBaseYear]="proposal.duration_id"
  [projectLength]="proposal.plan_length"
  (close)="closeClosureModal()">
</close-project-modal>

<!-- Closure Section Template -->
<ng-template #planCloseSection>
  <div class="card">
    <inline-alert *ngIf="isCloseRequested && !closeContext.reporting_approved" [small]="true" level="warning">
      <p class="no-margin">
        The fiscal reporting must be submitted and approved through <strong>{{closeContext.reporting_required_by}}</strong> before the plan can be closed.
      </p>
    </inline-alert>

    <div class="columns columns--2">
      <!-- Plan Closure Status -->
      <div>
        <ng-container [ngSwitch]="isClosed">
          <div class="card-title" *ngSwitchCase="true">
            <h3>Plan Closed</h3>
          </div>
          <div class="card-title" *ngSwitchCase="false">
            <h3>Closure Pending</h3>
          </div>
        </ng-container>
        <p class="labeled-text" id="qa_closure_status_date">
          <span class="bold">Plan End Date:</span> {{closeContext.close_date | po_date }}
        </p>
        <p class="labeled-text" id="qa_closure_status_reason">
          <span class="bold">Reason for Closure:</span> {{closeContext.close_reason}}
        </p>
        <p class="labeled-text" id="qa_closure_status_funds_outcomes" *ngIf="closeContext.funds_outcomes">
          <span class="bold">Outcomes for Remaining Funds:</span> {{closeContext.funds_outcomes}}
        </p>
        <p class="labeled-text" id="qa_closure_status_project_outcomes">
          <span class="bold">Outcomes for Plan:</span> {{closeContext.project_outcomes}}
        </p>
        <hr>
        <h4>Details of Request</h4>
        <p class="labeled-text" id="qa_requested_end_date">
          <span class="bold">Plan Closure Submittal Date:</span> {{closeContext.requested_at | po_date:'dateTime12HoursTZ'}}
        </p>
        <h6>Requested By:</h6>
        <profile-info [user]="closeContext.requester"></profile-info>
      </div>

      <div>
        <!-- Fiscal Reporting Status -->
        <ng-container *ngIf="isCloseRequested || (isClosed && closeContext.reporting_approved)">
          <h3 class="flex">
            {{closeContext.reporting_required_by}} Fiscal Reporting
            <span class="margin-left-sm" [ngClass]="closeContext.reporting_approved ? 'text-success' : 'text-error'"
            >{{closeContext.reporting_status}}</span>
          </h3>
          <table class="po-table">
            <tbody>
              <tr>
                <td class="align-right" >Budget</td>
                <td>{{ +closeContext.total_budget | currency:'USD':'symbol':'1.0' }}</td>
              </tr>
              <!-- <tr>
                <td class="align-right" >Already Deferred</td>
                <td>{{ +closeContext.total_deferred | currency:'USD':'symbol':'1.0' }}</td>
              </tr> -->
              <tr *ngIf="!isLegacyClosure">
                <td class="align-right">Allocations</td>
                <td>{{ +closeContext.total_allocations | currency:'USD':'symbol':'1.0' }}</td>
              </tr>
              <tr>
                <td class="align-right">Expenditures</td>
                <td>{{ +closeContext.total_expenditures | currency:'USD':'symbol':'1.0' }}</td>
              </tr>
            </tbody>
            <tfoot>
              <ng-container [ngSwitch]="isLegacyClosure">
                <tr *ngSwitchCase="true">
                  <td class="align-right">Remaining (Budget - Expenditures)</td>
                  <td>{{ +closeContext.remaining | currency:'USD':'symbol':'1.0' }}</td>
                </tr>
                <tr *ngSwitchCase="false">
                  <td class="align-right">Remaining (Allocations - Expenditures)</td>
                  <td>{{ +closeContext.remaining | currency:'USD':'symbol':'1.0' }}</td>
                </tr>
              </ng-container>
            </tfoot>
          </table>
        </ng-container>
        <!-- Close Approvers -->
        <ng-container *ngIf="closeApprovers && closeApprovers.length; else loadingApprovers">
          <div [ngClass]="{'certification-card-grid-container': closeApprovers.length > 1}">
            <app-approval-card-v2 class="certification-card" *ngFor="let approver of closeApprovers"
              [headerText]="approver.name"
              [users]="approver.users"
              [task]="approver.task"
              [replaceStates]="closeApprovalTexts"
              [allowed]="allowCloseApproval(approver.users)"
              [allowedRejectOnly]="allowCloseRejectOnly(approver.users)"
              (approve)="openCloseApproveAlert(approver.task)"
              (reject)="openRejectModal(approver.task)">
            </app-approval-card-v2>
          </div>
        </ng-container>
      </div>
    </div>

  </div>
</ng-template>

<!-- Reopen Alert -->
<alert *ngIf="showReopenAlert"
  button-action="Reopen"
  button-dismiss="Cancel"
  level="warning"
  (action)="reopen()"
  (dismiss)="toggleReopenAlert()">
  <p>
    By clicking “Reopen”, you are affirming that you are reopening this plan for the<br>
    sole purpose of updating the budget and/or completing unsubmitted fiscal reports.
  </p>
</alert>

<!-- Legacy Close Approve Alert -->
<alert *ngIf="showCloseApproveAlert"
  button-action="Ok"
  button-dismiss="Cancel"
  level="warning"
  (action)="closeApprove(taskToApproveClosure); showCloseApproveAlert=false;"
  (dismiss)="showCloseApproveAlert=false;">
  <p>
    By clicking 'Ok', you are confirming that you are closing this plan permanently and that you have verified that all fiscal reporting and budget adjustments are complete and certified.
    All associated contacts will be marked inactive and will no longer receive any notifications regarding this project, and all future budget items will be deleted.
    <b>This is permanent and cannot be undone.</b>
  </p>
</alert>
