<div class="card">
  <div class="profile-card__info">

    <!-- Title -->
    <div class="card-title">
      <h4 class="header-title h4">
        {{userRole.name}}
        <span *ngIf="roleAliases">({{expandAliases()}})</span>
      </h4>
    </div>

    <!-- Roles -->
    <table class="po-table">
      <thead>
      <tr class=" align-headings--left">
        <th scope="col">Fund</th>
        <th scope="col">Institution</th>
        <th scope="col">Project Type</th>
        <th scope="col">Project Name</th>
        <th scope="col" class="col-4-rem" *ngIf="canEditRole">&nbsp;</th>
      </tr>
      </thead>
      <tbody>
        <tr *ngFor="let roleScope of roleScopes; trackBy: trackById">
        <td>{{ fundName(userRole.data, roleScope) }}</td>
        <td>{{ institutionName(userRole.data, roleScope) }}</td>
        <td>{{ projectType(roleScope) }}</td>
        <td>
          <ng-container [ngSwitch]="!!roleScope.routerLink">
            <a *ngSwitchCase="true" class="text-link" [routerLink]="roleScope.routerLink">{{ projectName(roleScope) }}</a>
            <p *ngSwitchCase="false">{{ projectName(roleScope) }}</p>
          </ng-container>
        </td>
        <td class="align-center" *ngIf="canEditRole">
          <button class="action-button action-button--small action-button--tertiary icon-only" data-qa="delete-icon" (click)="removeRoleScope(roleScope)">
            <icon iconId="delete" class="icon--delete"></icon>
          </button>
        </td>
      </tr>
      </tbody>
    </table>

    <!-- Permissions -->
    <div>
      <h5 class="h6">Permissions</h5>
      <ng-container [ngSwitch]="userRole.data.abilities?.length > 0">
        <ul class="unordered-list" *ngSwitchCase="true">
          <li *ngFor="let ability of userRole.data.abilities">
            {{ ability }}
          </li>
        </ul>

        <p class="empty-state" *ngSwitchDefault>
          None
        </p>
      </ng-container>
    </div>
  </div>
</div>
