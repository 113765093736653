import { AREAS } from './permissions.const';
import { AREA_NAMES } from './resource.const';

export const FROALA_HIGHLIGHT_CLASS = 'fr-class-highlighted';

// example usage: comment-highlight-creator_id--123
export const COMMENT_ID_CLASSES = {
  ELEMENT_ID: 'comment-element_id', // element that comment belongs to. no associated style
  TEMP_ID: 'comment-temp_id', // new comment, not saved, not a draft. no associated style
  HIGHLIGHT_CREATOR_ID: 'comment-highlight-creator_id', // styled. allows creator to see their temp or draft comments highlighted
  DRAFT_ID: 'comment-draft-comment_id', // draft, saved comment. no associated style
  COMMENT_ID: 'comment-comment_id', // saved comment, not a draft. no associated style
  CREATOR_ID: 'comment-creator_id', // saved comment,  not a draft. no associated style,
  DRAFT: 'po-comment-draft'
};

export const COMMENT_AREAS = [
  // CAEP Areas
  AREAS.CFAD,
  AREA_NAMES.THREE_YEAR_PLAN,
  AREAS.ANNUAL_PLAN,
  AREAS.BUDGET,
  AREA_NAMES.WEP,
  AREAS.PROGRAM_AREA_REPORT,

  // SEP Areas
  AREAS.MID_ALLOCATION_REPORT,

  // Generic Areas
  AREAS.APPLICATION,
  AREAS.PLAN,
  AREAS.PROJECT,
  AREAS.FISCAL_REPORT,
];
