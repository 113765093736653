import { Component, Input, AfterViewInit, OnDestroy, OnInit, ElementRef, NgZone, ViewChild } from '@angular/core';
import { EVENT_CATEGORY } from '@app/core/services';
import { fromEvent, Subject } from 'rxjs';
import { debounceTime, skipWhile, takeUntil } from 'rxjs/operators';
import { AnalyticsService, CommentService } from '@app/core/services';

@Component({
  selector: 'comment-widget',
  templateUrl: './comment-widget.component.html',
})
export class CommentWidgetComponent implements AfterViewInit, OnDestroy, OnInit {
  @Input() institutionId: number;
  @Input() labelText: string;

  @ViewChild('commentWidget', { static: true }) commentWidgetElement: ElementRef;
  @ViewChild('commentButton', { static: true }) commentButtonElement: ElementRef;


  private destroy$: Subject<boolean> = new Subject();
  private useDefaultCommentText: boolean = true;
  private defaultCommentTextMaxLength = 40;

  hideWidget: boolean = true;

  isTextArea: boolean = false;
  isSelect: boolean = false;
  textAreaClicked: boolean = false;
  textAreaCallback: any = undefined;
  inputElementInfo: any = undefined;
  inputElementId: string = undefined;
  inputElement: HTMLElement = undefined;

  commentsBodyId = 'comments_body';
  commentWidgetId = 'comment-widget';
  commentWidgetShowClass = 'comment-widget__show';
  contentBodyContainerClass = 'content-body__container';
  elementHighlightedClass = 'comment-element-highlight';

  constructor(
    private commentService: CommentService,
    private analyticsService: AnalyticsService,
    private ngZone: NgZone
  ) {}

  ngOnDestroy() {
    this.destroy$.next(true);
    this.resetData();
  }

  ngOnInit() {
    // need some debounceTime on hideCommentWidget, in order to be able to click on the widget
    const hideDebounceTime = 150;

    this.commentService.hideCommentWidget.pipe(
      debounceTime(hideDebounceTime),
      skipWhile(val => !val),
      takeUntil(this.destroy$)
    ).subscribe((inputElementInfo) => {
      const { id, event } = inputElementInfo;
      if (!this.textAreaClicked && (!this.inputElementId || // If it wasn't the widget itself that was clicked, handle hiding it
        (id === this.inputElementId && (!event || event.relatedTarget !== this.commentButtonElement.nativeElement)))) {
        this.hideCommentWidget();
      }
    });

    this.commentService.showCommentWidget.pipe(
      skipWhile(val => !val),
      takeUntil(this.destroy$)
    ).subscribe(inputElementInfo => {
      this.showCommentWidget(inputElementInfo);
    });

    // Listen for comment data from a textArea
    this.commentService.textAreaCommentData.pipe(
      skipWhile(val => !val),
      takeUntil(this.destroy$)
    ).subscribe((textAreaCommentData: any) => {
      this.onTextAreaCommentData(textAreaCommentData);
    });
  }

  ngAfterViewInit() {
    fromEvent(window, 'resize')
      .pipe(
        debounceTime(500),
        takeUntil(this.destroy$))
      .subscribe(() => {
        if (this.inputElementId) {
          this.positionCommentWidget();
        }
      });
  }

  resetData() {
    this.clearElementHighlighting();
    this.inputElementId = undefined;
    this.inputElementInfo = undefined;
    this.inputElement = undefined;
    // this.commentWidgetElement = undefined;
    this.textAreaClicked = false;
    this.textAreaCallback = undefined;
    this.isTextArea = false;
    this.isSelect = false;
  }

  /**
   *
   * @param inputElementInfo
   */
  showCommentWidget(inputElementInfo: any) {
    this.resetData();

    this.inputElementInfo = inputElementInfo;
    this.inputElementId = inputElementInfo.id;

    const inputElementId = inputElementInfo.id;
    const inputElement = document.getElementById(inputElementId);
    if (!inputElement) {
      console.warn(`showCommentWidget: getElementById is null for ${inputElementId}.`);
      this.logErrorToAnalytics(`showCommentWidget: getElementById is null for ${inputElementId}.`)
    } else {
      this.inputElementInfo = inputElementInfo;
      this.inputElementId = inputElementInfo.id;
      this.inputElement = inputElement;
      /*
       * This variable change was triggered by an external library event (Froala), so we need to explicitly run it in
       * an Angular Zone so that the view can detect the change
       */
      this.ngZone.run(() => {
        this.hideWidget = false;
        this.isTextArea = inputElementInfo.isTextArea;
      });
      const positionSet = this.positionCommentWidget();
      if (positionSet) {
        this.setElementHighlight();
      }
    }
  }

  /**
   *
   */
  hideCommentWidget() {
    this.ngZone.run(() => {
      this.hideWidget = true;
    });

    this.commentWidgetElement.nativeElement.style['top'] = `0px`;
    this.resetData();
  }

  /**
   * Adds the elementHighlightedClass to visually mark the element that's being commented on
   * Attempts to find an area larger than just the input if possible
   */
  setElementHighlight() {
    const elementType = this.inputElementInfo.type;
    const closestTypes = ['input-field', 'po-select'];
    if (elementType) {
      closestTypes.unshift(elementType);
    }

    let closestElement: Element = null;
    for (const type of closestTypes) {
      const element = this.inputElement.closest(type);
      if (element) {
        closestElement = element;
        break;
      }
    }
    const highlightedElement = closestElement ?? this.inputElement;
    highlightedElement.classList.add(this.elementHighlightedClass);
  }

  /**
   * Removes the elementHighlightedClass from all elements that have it.
   * The elementHighlightedClass is used to visually link input elements with comment forms
   */
  clearElementHighlighting() {
    const highlightedElems = Array.from(document.querySelectorAll('.' + this.elementHighlightedClass));
    highlightedElems.forEach(el => {
      el.classList.remove(this.elementHighlightedClass);
    });
  }

  /**
   * Tell the froala editor to apply the highlight and open a new comment thread
   *
   */
  setTextAreaCommentClick() {
    this.textAreaClicked = true;
    this.commentService.setTextAreaCommentClick(this.inputElementId);
  }

  onTextAreaCommentData(commentData: any) {
    if (this.inputElementId !== commentData.element_id) {
      console.warn(`inputElementId ${this.inputElementId} not the same as in commentData elementId ${commentData.element_id}`);
      this.logErrorToAnalytics(`inputElementId ${this.inputElementId} not the same as in commentData elementId ${commentData.element_id}`)
      this.hideCommentWidget();
    }
    this.textAreaCallback = commentData.callback;
    this.openNewCommentThread(false);
  }

  /**
   * Tries to find some relevant text to display in the comment box, to help distinguish
   * between multiple draft comments.
   */
  get defaultCommentText() {
    if (!this.useDefaultCommentText) {
      return '';
    }

    const useLabelElementText = true;
    let defaultText = '';
    if (this.labelText) {
      defaultText = this.labelText;
    } else if (this.inputElementInfo?.labelText) {
      defaultText = this.inputElementInfo?.labelText;
    } else if (this.inputElement.ariaLabel) {
      defaultText = this.inputElement.ariaLabel;
    } else {
      const closestTypes = ['input-field', 'po-select'];
      if (this.inputElementInfo.type) {
        closestTypes.unshift(this.inputElementInfo.type);
      }
      for (const type of closestTypes) {
        const element = this.inputElement.closest(type);
        if (element && element.ariaLabel && (element.ariaLabel.length > 0)) {
          defaultText = element.ariaLabel;
          break;
        } else if (useLabelElementText && element) {
          // TODO - needs testing.
          // Not entirely sold on using the label contents
          const closestLabelElement = element.querySelector('label');
          if (closestLabelElement && (closestLabelElement.textContent.length > 0)) {
            defaultText = closestLabelElement.textContent;
            break;
          }
        }
      }
    }

    if (defaultText.length > this.defaultCommentTextMaxLength) {
      const words = defaultText.split(' ');
      let sentence = '';
      for (const word of words) {
        if ((sentence.length + word.length + 1) > this.defaultCommentTextMaxLength) {
          sentence = !!sentence.length ? `${sentence}...` : sentence;
          break;
        } else {
          sentence = `${sentence} ${word}`;
        }
      }
      defaultText = sentence;
    }
    // return defaultText ? `re: ${defaultText.slice(0, this.defaultCommentTextMaxLength)}` : '';
    return defaultText ? `re: ${defaultText}` : '';
  }

  /**
   *
   */
  get institutionIdForComment() {
    let institutionId;
    if (!!this.textAreaCallback?.institution_id) {
      institutionId = this.textAreaCallback.institution_id;
    } else if (!!this.institutionId) {
      institutionId = this.institutionId;
    } else {
      const closestFiscalReportingCard = document.getElementById(this.inputElementId).closest('fiscal-reporting-card');
      institutionId = !closestFiscalReportingCard ? undefined : parseInt(closestFiscalReportingCard.getAttribute('institutionId'));
    }
    return institutionId;
  }

  /**
   *
   */
  openNewCommentThread(isTemp: boolean, event?) {
    if (event) {
      // Prevent document click event causing comment card component to immediately deselect on creation
      event.stopPropagation();
    }

    this.commentService.openNewCommentThread(
      this.inputElementId,
      this.institutionIdForComment,
      isTemp,
      this.textAreaCallback
    );

    this.hideCommentWidget();
  }

  /**
   * Uses jquery to calculate a new absolute position top for a comment widget
   * Jquery .offset() is relative to the document, instead of the parent which simplifies calculations
   * Jquery .position() is relative to the parent, same as the non jquery element.offset().
   *
   * @returns
   */
  getCommentWidgetPositionTop(): number|boolean {
    const $inputElement = $('#' + CSS.escape(this.inputElementId));
    if (!$inputElement.length) {
      console.warn(`Couldn't find element with ID ${this.inputElementId}`);
      this.logErrorToAnalytics(`getCommentWidgetPositionTop: Couldn't find element with ID ${this.inputElementId} using jquery`)
      return false;
    }

    const $contentBodyContainer = $(`.${this.contentBodyContainerClass}`).first();
    if (!$contentBodyContainer.length) {
      console.warn(`Couldn't find the contentBodyContainer (${this.contentBodyContainerClass})`);
      this.logErrorToAnalytics(`getCommentWidgetPositionTop: Couldn't find the contentBodyContainer (${this.contentBodyContainerClass}) using jquery`)
      return false;
    }

    const contentBodyContainerOffset = $contentBodyContainer.offset();

    // relative to document
    const inputElementOffset = $inputElement.offset();

    // magic number. Froala Toolbar is about 50px high, and widget is 16px high
    const textAreaAdjust = this.isTextArea ? 58 : 0;
    const absolutePositionTop = $contentBodyContainer.scrollTop() + inputElementOffset.top - contentBodyContainerOffset.top + textAreaAdjust;
    return absolutePositionTop;
  }

  /**
   * Positions the comment widget along the outside right border of the content body container
   * using a calculated top value.
   *
   * @returns
   */
  positionCommentWidget(): boolean {
    const inputElement = this.inputElement || document.getElementById(this.inputElementId);

    if (!inputElement) {
      console.warn(`Couldn't find element with ID ${this.inputElementId}`);
      this.logErrorToAnalytics(`positionCommentWidget: Couldn't find element with ID ${this.inputElementId}`)
      return false;
    }

    // this.isTextArea = (inputElement.localName && inputElement.localName === 'po-html-textarea');
    this.isSelect = (inputElement.localName && inputElement.localName === 'po-select');

    const absoluteTop = this.getCommentWidgetPositionTop();
    if (absoluteTop === false) {
      return false;
    }

    this.commentWidgetElement.nativeElement.style['top'] = `${absoluteTop}px`;
    return true;
  }

  /**
   *
   * @param message
   */
  logErrorToAnalytics(message: string) {
    this.analyticsService.logEvent(
      EVENT_CATEGORY.comments,
      `comment-widget-error`,
      message,
    );
  }

}
