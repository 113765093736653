import { ModelBase } from './model.base';
import { isNumber } from 'lodash';

export interface IDuration {
  id: number;
  type: string;
  name: string;
  start_date?: string;
  end_date?: string;
  quarter?: number;
  year?: number;
  reportable?: boolean;
}

export class Duration extends ModelBase implements IDuration {
  public id: number;
  public type: string;
  public name: string;
  public start_date?: string;
  public end_date?: string;
  public quarter: number;
  public year: number;
  public reportable: boolean;

  constructor(raw?) {
    super();
    if (raw) {
      this.id = raw.id;
      this.type = raw.type;
      this.name = raw.name;
      this.start_date = raw.start_date;
      this.end_date = raw.end_date;
      this.quarter = raw.quarter;
      this.year = raw.year;
      this.reportable = raw.reportable;
    }
  }

  isQuarter() {
    return this.type === 'quarter';
  }

  /**
   * Formats an int Duration ID value, given the possible components of a duration: year, quarter, period, month
   *
   * @param options Specifies year, quarter, period and/or month values.
   * @returns {number} the duration_id
   */
  public static formatDurationId (options: {year: number, quarter?: number, period?: number, month?: number }): number {
    // Get out if we have no year.
    if (!options.year) {
      return null;
    }

    // If quarter is set, then return YYYY00Q
    if (isNumber(options.quarter)) {
      return parseInt(options.year + '00' + options.quarter, 10);
    }

    // If period is set, then return YYYY000PP
    if (isNumber(options.period)) {
      return parseInt(options.year + '000' + options.period.toString(10).padStart(2, '0'), 10);
    }

    // If month is set, then return YYYYMM
    if (isNumber(options.month)) {
      return parseInt(options.year + options.month.toString(10).padStart(2, '0'), 10);
    }

    // Else, return YYYY
    // At this point, we don't have quarter, period or month. Must be a year.
    return options.year;
  }

  public static getQuarterDurationIdsForYear(year: number) {
    const result = [1, 2, 3, 4].map(quarter => Duration.formatDurationId({ year, quarter }));
    return result;
  }

  /**
   * Constructs period duration id from the year & period.
   * @param year
   * @param period
   */
  public static getDurationIdFromPeriod(year: number, period: number): number {
    return Duration.formatDurationId({ year, period });
  }

  public static getYearFromDurationId(durationId: number) {
    return Number(String(durationId).slice(0, 4));
  }

  /**
   * Breaks down duration_id into year & period.
   * @param duration_id
   */
  public static getPeriodFromDurationId(durationId: number): {year: number, period: number} {
    const durationString = durationId.toString();

    const year = Number(durationString.substr(0, 4));
    const period = Number(durationString.substr(4, 5));

    return {year, period};
  }

  /**
    * Returns a string specifying season and year for a given duration_id
    * @param duration_id
    */
  public static getDurationSeasonString(duration: IDuration) {
    // quarters are in format YYYY000Q so % 10 returns the quarter/season
    switch (duration.id % 10) {
      case 1:
        return 'Summer - ' + duration.year;
      case 2:
        return 'Fall - ' + duration.year;
      case 3:
        return 'Winter - ' + duration.year;
      default:
        return 'Spring - ' + duration.year;
    }
  }
}

/**
 * Adds models definitions to ngrx-domains table.
 */
declare module '@app-ngrx-domains' {
  export namespace Model {
    export type Duration = IDuration;
  }
}
