import { ICommentsState, Model, Query, Queries, combineRootFactory } from '@app-ngrx-domains';

/**
 * Get the root states.
 */
const fromRoot = combineRootFactory<ICommentsState>('Comments');

/**
 * Predefined queries to run against root states.
 */
export interface ICommentsQueries {
  isCommentingEnabled: Query<boolean>;
  getParentResource: Query<Model.Resource>;
  getThreads: (resource: Model.Resource) => Query<Array<{ resource: Model.Resource, comment: Model.Comment, replies: Array<Model.Comment> }>>;
  getThread: (threadId: number) => Query<{ resource: Model.Resource, comment: Model.Comment, replies: Array<Model.Comment> }>;
}

Queries.Comments = {
  isCommentingEnabled: fromRoot(state => state.is_commenting_enabled),
  getParentResource: fromRoot(state => state.parent_resource),
  getThreads: (resource: Model.Resource) => fromRoot(state => {
    if (!state.threads) { return null; } // Still loading
    return Object.values(state.threads).filter(thread => {
      return Object.keys(resource).every(field => resource[field] === thread.resource[field]);
    });
  }),
  getThread: (threadId: number) => fromRoot(state => {
    return state.threads[threadId]
  })
};

/**
 * Add queries to ngrx domains type declarations.
 */
declare module '@app-ngrx-domains' {
  interface Root {
    Comments: Query<ICommentsState>;
  }

  interface Queries {
    Comments: ICommentsQueries;
  }
}
