import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
// import { Model } from '@app-ngrx-domains';
import { FileUploader } from 'ng2-file-upload';
import { EMPTY, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { FILE_UPLOAD_TYPES } from '../consts/';
import { AppUtils } from '../utilities';

@Injectable()
export class HelpdeskApiService {
  private hostname: string;
  private apiVersion = 'v1';

  constructor(private http: HttpClient) {
    this.hostname = environment.helpdeskApiUrl;
  }

  private urlPrefix(version?: string): string {
    return `${this.hostname}/${version ? version : this.apiVersion}`;
  }

  createTicket(body: any) {
    return this.http.post(this.urlPrefix('tickets'), body);
  }
}
