import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Actions, Model, Queries, State } from '@app-ngrx-domains';
import { ACTIONS, AREAS, AREA_NAMES, COMMENT_AREAS, COMMENT_ID_CLASSES, WORKFLOW_STEPS } from '@app/core/consts';
import { EnumErrorTypes } from '@app/core/models';
import { AnalyticsService, CommentService, EVENT_CATEGORY, PermissionsService, ProgramService } from '@app/core/services';
import { slideOverAnimation } from '@app/shared.generic/animations';
import { Store } from '@ngrx/store';
import { get } from 'lodash';
import { combineLatest, EMPTY, Subject } from 'rxjs';
import { debounceTime, filter, map, skipWhile, switchMap, take, takeUntil, takeWhile } from 'rxjs/operators';

@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  animations: [slideOverAnimation]
})
export class CommentsComponent implements OnInit, OnDestroy {
  proposal: Model.ProposalBase;
  institutionIds: Array<number> = [];
  durationId: number;
  isFiscalReport: boolean;
  institutionIdsByDuration: { [durationId: number]: number };
  currentUser: Model.User;

  commentThreads: Array<{ resource: Model.Resource, comment: Model.Comment, replies: Array<Model.Comment> }> = [];
  commentsDisabled: boolean;
  commentsLoaded: boolean;
  isCommentsOpen: boolean;

  bookmarkResource: Model.Resource;
  resourcesIdsByElement: { [elementId: string]: number };
  currentWorkflowStep: string;

  canEdit: boolean;
  canEditProject: boolean;
  canComment: boolean;
  canEditByInstitution: { [institutionId: number]: boolean } = {};

  // additional comment filter on top of the current bookmark resource (workflow_step, institution_id, duration_id)
  private currentFilter: { duration_id?: number, workflow_step?: string };
  private destroy$: Subject<boolean> = new Subject();
  private logAnalytics: boolean = true;

  constructor(
    private store: Store<State>,
    private programService: ProgramService,
    private analyticsService: AnalyticsService,
    private commentService: CommentService,
    private route: ActivatedRoute,
    private permissionsService: PermissionsService,
  ) { }

  resetComments() {
    this.currentFilter = undefined;
    this.commentThreads = [];
    this.commentsLoaded = false;
  }

  ngOnInit() {
    // watch for comment availability
    combineLatest([
      this.store.select(Queries.Layout.getBookmarkResource),
      this.store.select(Queries.Workflow.isVisible),
      this.store.select(Queries.Workflow.getCurrentStep)
    ]).pipe(
      map(([bookmarkResource, workflowVisible, currentWorkflowStep]) => {
        this.resetComments();

        this.bookmarkResource = bookmarkResource;
        this.currentWorkflowStep = currentWorkflowStep;
        if (!this.bookmarkResource) { return false; }

        // Comments available on Fiscal Reporting and non-Preview workflow steps
        const resourceArea = bookmarkResource && bookmarkResource.area_name || undefined;
        this.isFiscalReport = resourceArea === AREAS.FISCAL_REPORT;
        return (this.isFiscalReport) ||
          (resourceArea === AREA_NAMES.WEP) ||
          (COMMENT_AREAS.includes(resourceArea) &&
          workflowVisible &&
          this.currentWorkflowStep &&
          this.currentWorkflowStep !== WORKFLOW_STEPS.PREVIEW);
      }),
      filter((isAvailable) => {
        this.commentsDisabled = !isAvailable;
        if (!isAvailable) {
          this.store.dispatch(Actions.Comments.setCommentsEnabled(false));
        }
        return isAvailable;
      }),
      // Determine if comments have been disabled for the program
      filter(() => {
        const program = this.bookmarkResource && this.bookmarkResource.fund_id ? this.programService.getProgramById(this.bookmarkResource.fund_id) : undefined;
        const disabledAtProgram = program && program.program_settings ? program.program_settings.disable_comments : false;

        this.commentsDisabled = disabledAtProgram;
        if (disabledAtProgram) {
          this.store.dispatch(Actions.Comments.setCommentsEnabled(false));
        }

        return !disabledAtProgram;
      }),
      // Wait for commentable fields to become available
      switchMap(() => {
        return this.commentService.hasCommentableFields.pipe(
          debounceTime(50),
          takeWhile(() => !!this.bookmarkResource && !this.commentsDisabled)
        )
      }),
      filter((hasCommentableFields) => {
        this.store.dispatch(Actions.Comments.setCommentsEnabled(hasCommentableFields));
        return !!hasCommentableFields;
      }),
      // Fetch remaining data for permission checks & reporting resources
      switchMap(() => {
        return this.store.select(Queries.CurrentProposal.get).pipe(take(1))
      }),
      switchMap((proposal) => {
        this.proposal = proposal;

        this.store.dispatch(Actions.Comments.setResource(this.bookmarkResource));
        return this.isFiscalReport ? this.subscribeForReporting() : this.subscribeForWorkflow();
      }),
      takeWhile(() => !!this.currentFilter && !!this.bookmarkResource && !this.commentsDisabled),
      takeUntil(this.destroy$),
    ).subscribe((threads) => {
      if (!threads) { return; }

      this.commentThreads = threads.filter(t => t.comment?.temp_id != null || !t.comment?.draft || t.comment?.creator_id === this.currentUser.id);
      if (!this.commentsLoaded) {
        // Auto-expand/collapse comments panel
        this.store.dispatch(Actions.Layout.setCommentsOpen(!!this.commentThreads.length));
      }

      this.resourcesIdsByElement = threads.reduce((rbe, t) => {
        rbe[t.resource.element_id] = t.resource.id;
        return rbe;
      }, {});

      this.commentsLoaded = true;
    });

    this.store.select(Queries.Layout.isCommentsOpen).pipe(
      takeUntil(this.destroy$)
    ).subscribe(isCommentsOpen => {
      this.isCommentsOpen = isCommentsOpen;
    });

    this.store.select(Queries.Auth.getCurrentUser).pipe(
      skipWhile(val => !val),
      takeUntil(this.destroy$)
    ).subscribe(user => {
      this.currentUser = user;
      this.setUpDraftHighlightClass();
    });

    // watch for a comment being created by another component
    this.commentService.newCommentThreadData.pipe(
      skipWhile(val => !val),
      takeUntil(this.destroy$)
    ).subscribe((newCommentData) => {
      this.openNewCommentThread(newCommentData);
    });
  }

  subscribeForWorkflow() {
    this.formatResourceFilter();
    this.updateCanEditProject();

    return this.store.select(Queries.Comments.getThreads(this.currentFilter)).pipe(takeWhile(() => !!this.currentFilter));
  }

  subscribeForReporting() {
    return combineLatest([
      this.store.select(Queries.FiscalReports.getTasks),
      this.route.queryParams
    ]).pipe(
      debounceTime(50),
      takeWhile(() => this.bookmarkResource && this.isFiscalReport),
      switchMap(([tasks, queryParams]) => {
        let enableComments = true;
        // Format new resourceFilter
        this.durationId = this.numberOrNull(parseInt(queryParams['duration']));
        if (this.durationId != get(this.currentFilter, 'duration_id')) {
          this.resetComments();
        }
        this.formatResourceFilter();

        if (this.isFiscalReport) {
          this.updateInstitutionIdsByDuration(tasks);
          this.updateFiscalReportingPermissions();

          const durationIdKey = this.durationId ?? 'na';
          const institutionIds = this.institutionIdsByDuration[durationIdKey] ?? [];
          enableComments = !!institutionIds.length;
        }

        this.updateCanEditProject();

        if (!enableComments !== this.commentsDisabled) {
          this.commentsDisabled = !enableComments;
          this.store.dispatch(Actions.Comments.setCommentsEnabled(enableComments));
        }

        return (this.currentFilter && Object.keys(this.currentFilter).length && enableComments)
          ? this.store.select(Queries.Comments.getThreads(this.currentFilter)).pipe(takeWhile(() => this.currentFilter && !this.commentsDisabled))
          : EMPTY;
      })
    );
  }

  formatResourceFilter() {
    const currentFilter = {};
    if (this.durationId) {
      currentFilter['duration_id'] = this.durationId;
    }
    if (this.currentWorkflowStep) {
      currentFilter['workflow_step'] = this.currentWorkflowStep;
    }

    currentFilter['route'] = window.location.pathname;
    this.currentFilter = currentFilter;
  }

  /**
   * Modifies the document head to include a class unique to the user for displaying
   * draft comment highlights
   */
  setUpDraftHighlightClass() {
    if (!this.currentUser) {
      return;
    }

    const className = this.commentService.getCommentCreatorIdClass(this.currentUser.id);
    const draftStyle = '{ background-color: #fcc45d; }';
    const css = `.${className}.${COMMENT_ID_CLASSES.DRAFT} ${draftStyle}`;

    const head = document.getElementsByTagName('head')[0];
    const styleNodes = head.getElementsByTagName('style')
    let hasUserStyle = false;
    for (let i = 0; i < styleNodes.length; i++) {
      const innerText = styleNodes[i].innerText;
      if (innerText.includes(className)) {
        if (innerText === css) {
          hasUserStyle = true;
          continue;
        } else {
          // Remove node with class for different user
          const styleNode = styleNodes[i];
          styleNode.remove();
        }
      }
    }

    if (!hasUserStyle) {
      const style = document.createElement('style');
      style.appendChild(document.createTextNode(css));
      head.appendChild(style)
    }
  }

  closeComments() {
    this.store.dispatch(Actions.Layout.setCommentsOpen(false));
  }

  /**
   * takes submitted commentData and returns only the fields needed for an upsert
   * removes null/undefined values, so fields aren't null'd out
   * boolean values are converted from 0/1 to false/true
   * @param commentData
   * @returns
   */
  commentDataForUpsert(commentData: any): object {
    const properties = {
      id: { type: 'integer', required: false },
      type: { type: 'string', required: true, default: 'comment' },
      comment: { type: 'string' },
      draft: { type: 'boolean' },
      deleted: { type: 'boolean' },
      resolved: { type: 'boolean' },
      parent_id: { type: 'integer' },
      resource_id: { type: 'integer' },
      temp_id: { type: 'integer' },
      classes: { type: 'object' }
    }

    let fixedData = {};
    Object.entries(properties).forEach(([key, p]) => {
      if ((key in commentData) && commentData[key] !== undefined && commentData[key] !== null) {
        let value = commentData[key];
        if (p.type === 'boolean') {
          value = !!value;
        }
        fixedData[key] = value;
      } else if (!!p['required'] && !('default' in p)) {
        fixedData[key] = p['default'];
      }
    });

    return fixedData;
  }

  /**
   * expects an object with resourceData and commentData objects
   *
   * @param newCommentData object
   * @returns
   */
  openNewCommentThread(newCommentData: any = {}) {
    const elementId = newCommentData.element_id;

    if (!newCommentData.creator_id) {
      newCommentData.creator_id = this.currentUser.id;
    }

    newCommentData.resource_id = this.resourcesIdsByElement[elementId];

    this.store.dispatch(Actions.Comments.createThread(
      { ...this.currentFilter, element_id: elementId },
      this.commentDataForUpsert(newCommentData),
      newCommentData.isTemp,
      newCommentData.callback
    ));
  }

  /**
   * Used to limit commenting on fiscal reports
   * @param tasks
   */
  updateInstitutionIdsByDuration(tasks: any) {
    let completedByDuration = [];
    const institutionIds = [];
    completedByDuration = tasks.reduce((durations: any, task: any) => {
      const durationId = task.duration_id ?? 'na';
      if (task.institution_id && !!task.completed) {
        if (!durations[durationId]) {
          durations[durationId] = [];
        }
        durations[durationId].push(task.institution.id);
      }
      return durations;
    }, {});

    // only want to enable comment for fiscal reports that aren't completed
    const institutionIdsByDuration = tasks.reduce((durations: any, task: any) => {
      const durationId = task.duration_id ?? 'na';
      if (task.institution_id) {
        const institution_id = task.institution_id;
        if (!institutionIds.includes(institution_id)) {
          institutionIds.push(institution_id);
        }
        this.institutionIds.push(task.institution_id);
        const isCompleted = completedByDuration?.[durationId]?.includes(institution_id) ?? false;
        if (!durations[durationId]) {
          durations[durationId] = [];
        }
        if (!isCompleted) {
          durations[durationId].push(task.institution.id);
        }
      }
      return durations;
    }, {});

    this.institutionIds = institutionIds;
    this.institutionIdsByDuration = institutionIdsByDuration;
  }

  /**
   * based on lookupPermissions() in fiscal-reporting.component.ts
   */
  updateFiscalReportingPermissions() {
    // const fundId = (this.proposal.fund_ids && this.proposal.fund_ids.length) ? this.proposal.fund_ids[0] : this.program.id;
    const fundId = this.bookmarkResource.fund_id ?? ((this.proposal.fund_ids && this.proposal.fund_ids.length) ? this.proposal.fund_ids[0] : undefined);
    if (!fundId) {
      console.warn(`fund_id not found in resource or proposal`, this.bookmarkResource, this.proposal);
      this.logAnalyticsEvent('error', `fund_id not found in resource ${document.location.pathname} or proposal ID ${this.proposal.id}`);
    }

    const resources = [];

    // Add checks for canEdit for each commentable institution, based on tasks
    // We're got an array of actions below, with just ACTIONS.EDIT left over from the code in lookupPermissions()
    // leaving the array for now, in case we need to check for something like ACTIONS.VIEW
    this.institutionIds.forEach((institutionId: number) => {
      [ACTIONS.EDIT].forEach(action => {
        resources.push({
          action: action,
          area: AREAS.FISCAL_REPORT,
          fund_id: fundId,
          proposal_id: this.proposal.id,
          institution_id: institutionId
        });
      });
    });

    this.permissionsService.canResources(resources).subscribe(response => {
      const canEditByInstitution = {};
      response.forEach(resource => {
        if (resource.action === ACTIONS.EDIT && resource.institution_id) {
          canEditByInstitution[resource.institution_id] = resource.allowed;
        }
      });
      this.canEditByInstitution = canEditByInstitution;
      this.updatePermissions();
    });
  }

  /**
   * Check to see if the user has canEdit for the project.
   */
  async updateCanEditProject() {
    // In testing, this simpler check comes back as false. It looks like the proposal ID isn't being parsed out of this.route.snapshot
    // const canEditProject = await this.permissionsService.canEditProject(this.proposal, AREAS.PROJECT, this.route.snapshot).toPromise();

    // Need to use explicity use AREAS.PROJECT, instead of this.resource.area_name.
    const resourceToCheck = {
      action: ACTIONS.EDIT,
      area: AREAS.PROJECT,
      fund_id: this.bookmarkResource.fund_id,
      proposal_id: this.proposal.id,
      institution_id: this.bookmarkResource.institution_id
    };
    const checkResources = [resourceToCheck];
    const canResources = await this.permissionsService.canResources(checkResources).toPromise();
    this.canEditProject = canResources && canResources[0]?.allowed;
    this.updatePermissions();
  }

  /**
   * This function should be updated whenever a new type of permission looked is added
   * And called whenever a permission is looked up.
   * Starting simple with just canEditProject and canEditByInstitution for fiscal-reports
   */
  updatePermissions() {
    this.canEdit = this.canEditProject;
    this.canComment = this.canEditProject || Object.values(this.canEditByInstitution).includes(true);
  }

  trackById(index, item) {
    return item.comment?.temp_id || item.comment?.id;
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();

    this.isCommentsOpen = false;
  }

  /**
   *
   * @param value
   * @returns number|null
   */
  numberOrNull(value): number|null {
    return !Number.isNaN(value) ? value : null;
  }

  /**
   *
   * @param action - ex. 'delete-comment-delete', 'delete-comment-delete-error'
   * @param labelData
   */
  logAnalyticsEvent(action: string, labelData: string, hasError=false) {
    if (this.logAnalytics) {
      if (hasError) {
        action = action + '-error';
      }
      this.analyticsService.logEvent(EVENT_CATEGORY.comments, action, labelData);
    }
  }

  /**
   * helper for consistency in analytics logging
   * @param comment
   * @param errorData
   * @returns
   */
  analyticsLabelData(comment: any, errorData: any = undefined): string {
    const labelData = {
      comment_id: comment?.id,
      created_at: comment?.created_at,
      updated_at: comment?.updated_at,
    };

    if (errorData && !!Object.keys(errorData)?.length) {
      labelData['error_message'] = errorData.message;
    }

    return JSON.stringify(labelData);
  }

  // Currently not in use
  /**
   * Returns a basic Model.ErrorHandler object that defaults to just logging to server
   * logToServer is not currently implmented, but it should be, so this is meant to future proof
   * @param type
   * @param error
   * @param message
   * @param details
   * @returns
   */
  setErrorObject(type: EnumErrorTypes, error: any, message: string = '', details: string = ''): Model.ErrorHandler {
    return {
      type: type,
      location: this.constructor.name,
      logToServer: true,
      show: false,
      refresh: false,
      raw: error,
      details: details,
      message: message,
      };
  }
}
