import { State, Model } from '@app-ngrx-domains';

/**
 * States managed by reducer; used also as initial states
 */
State.Comments = {
  is_commenting_enabled: true,
  is_comments_open: false,

  focused_element_id: undefined,

  parent_resource: undefined,

  threads: null
};

/**
 * Adds states definitions to ngrx-domains table.
 */
declare module '@app-ngrx-domains' {
  export interface ICommentsState {
    is_commenting_enabled: boolean; // Current program has enabled commenting
    is_comments_open: boolean; // Comments side-panel is expanded

    focused_element_id: string;

    parent_resource: Model.Resource;

    threads?: {
      [threadId: number]: {
        resource: Model.Resource,
        comment: Model.Comment,
        replies: Array<Model.Comment>
      }
    }
  }

  interface State {
    Comments: ICommentsState;
  }
}
