import { Component, Input, OnInit, OnDestroy, TemplateRef, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder } from '@angular/forms';
import { combineLatest, forkJoin, Observable, Subject } from 'rxjs';
import { filter, withLatestFrom, takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { State, Queries, Actions, Model } from '@app-ngrx-domains';
import { ApiService, LookupService, PermissionsService, ProgramService } from '@app-services';
import { Budget, ProposalBase as Proposal, ProposalBudget, Utilities, Workflow } from '@app-models';
import { AREAS, ACTIONS, PROGRAM_KEYS, SYSTEM_ROLES, WORKFLOW_STEPS, TASK_TYPES, STATES, CHAR_LIMITS } from '@app-consts';

@Component({
  selector: 'base-project-preview',
  templateUrl: './base-project-preview.component.html'
})
export class BaseProjectPreviewComponent implements OnInit, OnDestroy {
  @Input() previewSection: TemplateRef<any>;
  @Input() planName: string;
  @Input() areaName: string = AREAS.PLAN;
  @Input() permissionsArea: string = AREAS.PROJECT;
  @Input() supportsPlanClosure: boolean = false;
  @Input() supportsFiscalReporting: boolean = false;
  @Input() approverContactTypes: Array<number> = [];
  @Input() set approversOverride(approvers: Array<{ name: string, role_id: number, task: Model.Task, users: Array<Model.User>}>) {
    this.overrideApprovers = true;
    this.approvers = approvers;
  }
  @Input() planSubmitTaskType: string = TASK_TYPES.RFA_PLAN_SUBMIT;
  @Input() planApproveTaskType: string = TASK_TYPES.RFA_PLAN_APPROVE;
  @Input() planCloseSubmitTaskType: string = TASK_TYPES.RFA_PLAN_CLOSE_SUBMIT;
  @Input() planCloseApproveTaskType: string = TASK_TYPES.RFA_PLAN_CLOSE_APPROVE;
  @Input() emitSubmit: boolean = false;
  @Input() emitApproval: boolean = false;
  @Input() hideHistory: boolean = false;
  @Input() durationId: number;
  @Input() disableSubmit: boolean = false;
  @Input() previewLink: string; // Overrides the default "Proposal.routeLink" result
  @Input() guidance: Model.GuidanceWorkflowFilter;

  @Output() onSubmit: EventEmitter<Model.Task> = new EventEmitter();
  @Output() onApproval: EventEmitter<Model.Task> = new EventEmitter();
  public fundName = '';

  public rejectProposalForm: FormGroup;
  public emailOptions = {};
  public commentsLength = CHAR_LIMITS.EXTRA_MEDIUM;

  public programKey: string;
  public proposal: any;

  public projectState: string;
  public isSubmitted = false;
  public isCertified = false;
  public isCloseRequested = false;
  public isClosed = false;
  public isLegacyClosure = false;

  public approvalTasks: Array<Model.Task>;
  public currentApprovalStep: number;
  public closeTask: Model.Task;
  public isLegacyCloseTask: boolean = false;
  public approvers: Array<{ name: string, role_id: number, task: Model.Task, users: Array<Model.User>}> = [];
  public closeApprovers: Array<{ name: string, role_id: number, task: Model.Task, users: Array<Model.User>}>;
  public closeApprovalTasks: Array<Model.Task>;
  public closeContext: any;
  public closeApprovalTexts = {
    approved: { action: 'Approve', done: 'Plan Closure Approved', await: 'Pending Closure' },
  }

  public canShowFiscalReporting = false;
  public fiscalReportingLink: string;

  public showSubmitAlert = false;
  public showUnsubmitAlert = false;
  public unsubmitText: string;
  public showRejectModal = false;

  public legacyBudgetItems: Array<Model.BudgetItem>;
  public showCloseProposalModal = false;
  public showReopenAlert = false;
  public showCloseApproveAlert = false;
  public taskToApproveClosure: Model.Task;

  public state: string;
  public initialized = false;
  private currentUser: Model.User;
  private currentWorkflow: Model.Workflow;
  private contacts: Array<Model.UserRoleScope>;
  private overrideApprovers: boolean = false;
  private taskToReject: Model.Task;

  private canSubmit = false;
  private canCertify = false;
  private canReopen = false;

  private actions: {[action: string]: Model.HeaderAction} = {
    share:
      { id: 1, route: 'share', type: 'button', title: 'Share', hide: false },
    submit:
      { id: 2, route: 'submit', type: 'button', title: 'Submit', hide: true, footer: true },
    unsubmit:
      { id: 3, route: 'unsubmit', type: 'button', title: 'Decertify', hide: true },
    close:
      { id: 4, route: 'submit-close', type: 'button', title: 'Close Plan', hide: true },
    reopen:
      { id: 4, route: 'reopen', type: 'button', title: 'Reopen Plan', hide: true },
  };

  private parentProgram: Model.Fund;
  private destroy$: Subject<boolean> = new Subject();

  constructor(
    private apiService: ApiService,
    private lookupService: LookupService,
    private permissionsService: PermissionsService,
    private programService: ProgramService,
    private route: ActivatedRoute,
    private _fb: FormBuilder,
    private store: Store<State>,
  ) {}

  ngOnInit() {
    // clear out preview related action buttons.
    this.store.dispatch(Actions.Layout.appendActions([]));
    this.store.dispatch(Actions.Workflow.setCurrentStep(WORKFLOW_STEPS.PREVIEW));

    combineLatest([
      this.store.select(Queries.CurrentProposal.get),
      this.store.select(Queries.Workflow.getCurrent),
      this.store.select(Queries.Budget.getItems),
    ]).pipe(
      withLatestFrom(
        this.store.select(Queries.Contacts.get),
        this.store.select(Queries.Auth.getCurrentUser),
      ),
      filter(([[p, w, b], c, u]) => p && !!w),
      takeUntil(this.destroy$)
    ).subscribe(([[proposal, current_workflow, budget_items], proposal_contacts, current_user]) => {

      if (!proposal.id) {
        // in transition - force sub-components to be destroyed
        this.initialized = false;
        return;
      }

      this.proposal = proposal;
      this.currentWorkflow = current_workflow;
      this.currentUser = current_user;
      this.legacyBudgetItems = budget_items;

      this.projectState = Proposal.getAreaState(this.proposal, this.areaName, this.durationId);
      this.isSubmitted = Proposal.isAreaSubmitted(this.proposal, this.areaName, this.durationId);
      this.isCertified = Proposal.isAreaCertified(this.proposal, this.areaName, this.durationId);

      this.approvalTasks = (this.planApproveTaskType !== TASK_TYPES.SWP_PROPOSAL_APPROVE)
        ? proposal.tasks.filter((t: Model.Task) => t.task_type === this.planApproveTaskType)
        : proposal.tasks.filter((t: Model.Task) => t.task_type === TASK_TYPES.SWP_PROPOSAL_APPROVE && (t.institution_id === this.proposal.approver_institution_id));

      if (this.durationId) {
        this.approvalTasks = this.approvalTasks.filter((t: Model.Task) => t.duration_id === this.durationId);
      }

      if (this.approvalTasks.some(task => !!task.client_state.step)) {
        const approvalSteps = this.approvalTasks.reduce((steps, task) => {
          if (!steps.includes(task.client_state.step)) {
            steps.push(task.client_state.step);
          }
          return steps;
        }, []).sort((a, b) => a - b);

        this.currentApprovalStep = approvalSteps.find(step => this.approvalTasks.some(task => task.client_state.step === step && !task.completed));
      }

      if (this.supportsPlanClosure) {
        this.isLegacyClosure = this.planCloseSubmitTaskType === this.planCloseApproveTaskType;
        if (this.isLegacyClosure) {
          this.refreshLegacyPlanCloseCard();
        } else {
          this.refreshPlanCloseCard();
        }
      }

      if (this.initialized) {
        this.refreshActions();
        this.buildApprovers();
      } else {
        // run through one time set up code.
        this.initialize(proposal_contacts);
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  private initialize(proposal_contacts: Array<Model.UserRoleScope>) {
    if (this.initialized) {
      // all's been setup.
      return;
    }

    this.initialized = true;
    const program = this.proposal.funds[0];
    this.programKey = program.parent_key || program.key;
    this.parentProgram =  !!program.parent_key
      ?  this.programService.getParentProgramByKey(this.programKey) : this.programService.getProgramById(program.id);
    this.fundName = this.parentProgram.name;

    this.rejectProposalForm = this._fb.group({
      comments: ['']
    });

    this.emailOptions = {
      subject: `${this.fundName}: ${this.proposal.title}`,
      previewPrefix: this.previewLink || Proposal.routerLink(this.proposal, WORKFLOW_STEPS.PREVIEW),
    };

    this.unsubmitText = 'By decertifying your plan, you are sending it back into draft state' + (this.supportsFiscalReporting
      ? ` where fiscal reporting will not be possible. The plan must be re-submitted and certified to be eligible for fiscal reporting.
        If removing a collaborative partner, remember to unsubmit any fiscal reports associated with them before decertifying this plan.`
      : '.'
    );

    this.fiscalReportingLink = Utilities.fiscalReportingLink(this.proposal);

    // see if user has permissions.
    if ([PROGRAM_KEYS.EWD_RD, PROGRAM_KEYS.EWD_SD].includes(program.key)) {
      // add sector monitor to approval list.
      this.approverContactTypes.push(SYSTEM_ROLES.SECTOR_FUND_MONITOR.ID);
    }

    const getSystemRoleUsers = []; // system role users to get
    const systemRoleIds = []; // system role users expected
    this.approverContactTypes.forEach(roleId => {
      const roleData = this.lookupService.getRole(roleId);
      if (!roleData.proposal) {
        // handle system roles only.
        const scope = {
          roleId,
        };
        if (roleData.fund) {
          scope['parentKey'] = this.parentProgram.key;
        }
        if (roleData.institution) {
          if (roleId === SYSTEM_ROLES.CERTIFYING_AUTHORITY.ID && this.programKey === PROGRAM_KEYS.SWP_K12) {
            scope['institutionId'] = this.proposal.application_region;
          } else if (roleId === SYSTEM_ROLES.CERTIFYING_AUTHORITY.ID && [PROGRAM_KEYS.SWP_L, PROGRAM_KEYS.SWP_R].includes(this.programKey)) {
            scope['institutionId'] = this.proposal.approver_institution_id;
          } else if (roleId === SYSTEM_ROLES.REGIONAL_FUND_MONITOR.ID && [PROGRAM_KEYS.SWP_L, PROGRAM_KEYS.SWP_R].includes(this.programKey)) {
            scope['institutionId'] = this.proposal.application_region;
          } else {
            scope['institutionId'] = this.proposal.lead_institution.id;
          }
        }
        if (roleData.sector) {
          scope['sectorId'] = this.proposal.sector_id;
        }
        getSystemRoleUsers.push(this.lookupService.getProgramUsersByRole$(scope));
        systemRoleIds.push(roleId);
      }
    });

    forkJoin([
      ...getSystemRoleUsers,
      this.permissionsService.canCanProject(this.proposal, this.permissionsArea, [
        ACTIONS.SUBMIT, ACTIONS.CERTIFY, ACTIONS.REOPEN
      ], this.route.snapshot)
    ]).subscribe((data: Array<any>) => {
      // build contacts
      this.contacts = [...proposal_contacts];
      for (let dataIndex = 0; dataIndex < data.length - 1; dataIndex++) {
        const users = data[dataIndex];
        users.map(user => {
          this.contacts.push({
            role_id: systemRoleIds[dataIndex],
            user,
            user_id: user.id
          })
        })
      }
      this.buildApprovers();

      const permissions: Array<Model.PermResourceResponseItem> = data[data.length - 1];
      this.canSubmit = !!permissions.find(p => p.action === ACTIONS.SUBMIT && p.allowed);
      this.canCertify = !!permissions.find(p => p.action === ACTIONS.CERTIFY && p.allowed);
      this.canReopen = !!permissions.find(p => p.action === ACTIONS.REOPEN && p.allowed);

      this.refreshActions();
    });

    // Listen for button actions.
    this.store.select(Queries.Layout.getEmitState)
      .pipe(takeUntil(this.destroy$))
      .subscribe((emitted: { name: string }) => {
        if (!emitted) {
          return;
        }
        if (emitted.name === this.actions.submit.route) {
          this.openSubmitAlert();
        } else if (emitted.name === this.actions.unsubmit.route) {
          this.toggleUnsubmitAlert();
        } else if (emitted.name === this.actions.close.route) {
          this.showCloseProposalModal = true;
        } else if (emitted.name === this.actions.reopen.route) {
          this.toggleReopenAlert();
        }
      });
  }

  private buildApprovers() {
    if (this.overrideApprovers) {
      return;
    }

    this.approvers = [];
    this.approverContactTypes.forEach(role_id => {
      const approveTask = (this.approverContactTypes.length === 1)
        ? this.approvalTasks.length > 0 ? this.approvalTasks[0] : undefined // just one approving role
        : this.approvalTasks.find(t => t.role_id === role_id);

      this.approvers.push({
        name: this.getRoleName({ role_id }),
        role_id,
        task: approveTask,
        users: this.contacts?.filter(c => c.role_id === role_id).map(c => ({ ...c.user })),
      })
    });

    if (this.closeApprovalTasks && this.closeApprovalTasks.length) {
      this.closeApprovers = [];
      // for swp l/r v2 & v3, remove any secondary approvers for close plan
      const closeApproverContactTypes = this.approverContactTypes.filter(role_id =>
        ![PROGRAM_KEYS.SWPR_v2, PROGRAM_KEYS.SWPL_v2, PROGRAM_KEYS.SWPR_v3, PROGRAM_KEYS.SWPL_v3].includes(this.proposal.funds[0].key) || role_id === SYSTEM_ROLES.CERTIFYING_AUTHORITY.ID);
      closeApproverContactTypes.forEach(role_id => {
        const closeApproveTask = (closeApproverContactTypes.length === 1)
          ? this.closeApprovalTasks.length > 0 ? this.closeApprovalTasks[0] : undefined // just one approving role
          : this.closeApprovalTasks.find(t => t.role_id === role_id);
        this.closeApprovers.push({
          name: this.getRoleName({ role_id }),
          role_id,
          task: closeApproveTask,
          users: this.contacts?.filter(c => c.role_id === role_id).map(c => ({ ...c.user })),
        })
      });
    }
  }

  private refreshActions() {
    if (this.destroy$.isStopped) {
      return;
    }

    // set up action buttons.
    const actions: Model.HeaderAction[] = [
      {// Share Button
        ...this.actions.share,
      },
    ];

    this.canShowFiscalReporting = Proposal.hasAreaState(this.proposal, TASK_TYPES.FISCAL_REPORT_SUBMIT);

    if (this.isClosed) {
      if (this.canReopen) {
        actions.push({ ...this.actions.reopen, hide: false }); // Plan Reopen
      }
    } else {
      if (this.canSubmit) {
        if (this.isCloseRequested) {
          // no buttons to show
        } else if (this.isCertified) {
          if (this.programKey === PROGRAM_KEYS.SEP) {
            const midAllocationReportState = Proposal.getAreaState(this.proposal, AREAS.MID_ALLOCATION_REPORT);
            if (![STATES.SUBMITTED.name, STATES.CERTIFIED.name].includes(midAllocationReportState)) {
              actions.push({...this.actions.unsubmit, hide: false }); // Unsubmit
            }
          } else {
            actions.push({...this.actions.unsubmit, hide: false }); // Unsubmit
            if (this.supportsPlanClosure) {
              actions.push({ ...this.actions.close, hide: false }); // Plan Close
            }
          }
        } else if (!this.isSubmitted) {
          actions.push({...this.actions.submit, hide: false, disabled: this.disableSubmit }); // Submit
        }
      }
    }

    this.store.dispatch(Actions.Layout.appendActions(actions));
  }

  private get isValidWorkflow(): boolean {
    return this.currentWorkflow ? Workflow.isValid(this.currentWorkflow) : false;
  }

  private getRoleName(contact: Model.UserRoleScope): string {
    let name: string;
    if (contact.role) {
      name = contact.role.long_name;
    } else {
      name = this.lookupService.getRoleLongName(contact.role_id);
    }
    return name;
  }

  private refreshLegacyPlanCloseCard() {
    this.closeTask = this.proposal.tasks.find((t: Model.Task) => t.task_type === this.planCloseSubmitTaskType);
    if (!this.closeTask) {
      // closure has not been requested... or just got reopened
      this.isCloseRequested = false;
      this.isClosed = false;
      this.closeContext = undefined;
      return;
    }

    this.isCloseRequested = Proposal.areaCloseRequested(this.proposal, this.areaName);
    this.isClosed = Proposal.isAreaClosed(this.proposal, this.areaName);
    if (!this.isCloseRequested && !this.isClosed) {
      // closure has not been requested... or just got reopened
      this.closeContext = undefined;
      return;
    }

    this.closeApprovalTasks = this.proposal.tasks.filter((t: Model.Task) => t.task_type === this.planCloseApproveTaskType);
    const closeoutResponses: Model.EACloseoutResponses = this.proposal.closeout_responses || {};
    let requester: Model.User;
    if (this.closeTask.performed_by) {
      requester = this.closeTask.performed_by;
    }

    // determine when to close the fiscal year & budget by program type
    let closeFiscalYear = this.lookupService.getFiscalYear(closeoutResponses.close_date);
    let totalBudget: number;

    if ([PROGRAM_KEYS.SWP_L, PROGRAM_KEYS.SWP_R].includes(this.proposal.funds[0].parent_key)) {
      const lastReportingYearString = `${this.proposal.duration_id + this.proposal.plan_length - 1}-06-30`;
      const lastReportingYear = this.lookupService.getFiscalYear(lastReportingYearString);
      if (lastReportingYear.id < closeFiscalYear.id) {
        closeFiscalYear = lastReportingYear;
      }
    }

    if ([PROGRAM_KEYS.SWPL_v1, PROGRAM_KEYS.SWPR_v1].includes(this.proposal.funds[0].key)) {
      totalBudget = Budget.costsTypeTotal(this.legacyBudgetItems, 'reporting');
    } else {
      totalBudget = Budget.costsTypeTotal(this.proposal.plan_budget_items, 'reporting', 'direct_amount');
    }

    const fiscalReportApprovalDurationId = this.lookupService.quarterDurationId(closeFiscalYear.id, 4);
    const fiscalReportApprovalStatus = Proposal.getAreaState(this.proposal, TASK_TYPES.FISCAL_REPORT_APPROVE, fiscalReportApprovalDurationId);

    this.closeContext = {
      close_date: closeoutResponses.close_date,
      close_reason: closeoutResponses.close_reason || 'Plan Closed',
      project_outcomes: closeoutResponses.project_outcomes || 'N/A',
      funds_outcomes: closeoutResponses.funds_outcomes,
      requester,
      requested_at: this.closeTask.created_at,

      reporting_required_by: `${closeFiscalYear.name} Quarter 4`,
      reporting_required_by_id: fiscalReportApprovalDurationId,
      reporting_approved: fiscalReportApprovalStatus === STATES.APPROVED.name,
      reporting_status: fiscalReportApprovalStatus === STATES.APPROVED.name ? 'Completed' : 'Incomplete',

      total_budget: totalBudget,
      total_allocations: totalBudget,
      remaining: totalBudget,
      total_expenditures: 0,
      total_deferred: 0, // not used
    }

    // get total expenditures
    const submittedDurationIds = Proposal.getSubmittedDurationIds(this.proposal, TASK_TYPES.FISCAL_REPORT_SUBMIT);
    if (submittedDurationIds.length) {
      this.apiService.getExpenditures({ proposal_ids: [this.proposal.id], duration_ids: submittedDurationIds })
        .subscribe((expenditures) => {
          this.closeContext.total_expenditures = 0;
          (expenditures || []).forEach(expenditure => {
            this.closeContext.total_expenditures += expenditure.lines.reduce((total: number, line: Model.FiscalReportLine) => total + (line.amount || 0), 0);
          });
          this.closeContext.remaining = this.closeContext.total_allocations - this.closeContext.total_expenditures;
        }, Utilities.setCallbackError(this.store, this.constructor.name));
    }

    if (!requester && !!this.closeTask.store.requester) {
      this.apiService.getProfileById(this.closeTask.store.requester).subscribe(user => {
        this.closeContext.requester = user;
      })
    }
  }

  private refreshPlanCloseCard() {
    this.closeTask = this.proposal.tasks.find((t: Model.Task) => t.task_type === this.planCloseSubmitTaskType);
    if (!this.closeTask || !this.closeTask.completed) {
      // closure has not been requested... or just got reopened
      this.isCloseRequested = false;
      this.isClosed = false;
      this.closeContext = undefined;
      return;
    }

    this.closeApprovalTasks = this.proposal.tasks.filter((t: Model.Task) => t.task_type === this.planCloseApproveTaskType);
    this.isCloseRequested = Proposal.areaCloseRequested(this.proposal, this.areaName);
    this.isClosed = Proposal.isAreaClosed(this.proposal, this.areaName);
    if (!this.isCloseRequested && !this.isClosed) {
      // closure has not been requested... or just got reopened
      this.closeContext = undefined;
      return;
    }

    const closeoutResponses: Model.EACloseoutResponses = this.proposal.closeout_responses || {};
    let requester: Model.User;
    if (this.closeTask.performed_by) {
      requester = this.closeTask.performed_by;
    }

    // fiscal year the close date falls in
    const closeFiscalYear = this.lookupService.getFiscalYear(closeoutResponses.close_date);
    const fiscalReportApprovalDurationId = this.lookupService.quarterDurationId(closeFiscalYear.id, 4);
    const fiscalReportApprovalStatus = Proposal.getAreaState(this.proposal, TASK_TYPES.FISCAL_REPORT_APPROVE, fiscalReportApprovalDurationId);
    const totalBudget = ProposalBudget.getBudgetedAmounts(this.proposal);
    const totalDeferred = ProposalBudget.getDeferredAllocationsTotal(this.proposal.allocations);
    const totalAllocations = ProposalBudget.getAllocationsAmount(this.proposal.allocations);

    this.closeContext = {
      close_date: closeoutResponses.close_date,
      close_reason: closeoutResponses.close_reason || 'Plan Closed',
      project_outcomes: closeoutResponses.project_outcomes || 'N/A',
      funds_outcomes: closeoutResponses.funds_outcomes || undefined,
      requester,
      requested_at: this.closeTask.created_at,

      reporting_required_by: `${closeFiscalYear.name} Quarter 4`,
      reporting_required_by_id: fiscalReportApprovalDurationId,
      reporting_approved: fiscalReportApprovalStatus === STATES.APPROVED.name,
      reporting_status: fiscalReportApprovalStatus === STATES.APPROVED.name ? 'Completed' : 'Incomplete',

      total_budget: totalBudget.direct_costs + totalBudget.indirect_costs,
      total_deferred: totalDeferred,
      total_allocations: totalAllocations,
      total_expenditures: 0,
      remaining: totalAllocations,
    }

    const submittedDurationIds = Proposal.getSubmittedDurationIds(this.proposal, TASK_TYPES.FISCAL_REPORT_SUBMIT);
    if (submittedDurationIds.length) {
      this.apiService.getExpenditures({ proposal_ids: [this.proposal.id], duration_ids: submittedDurationIds })
        .subscribe((expenditures) => {
          this.closeContext.total_expenditures = 0;
          (expenditures || []).forEach(expenditure => {
            this.closeContext.total_expenditures += expenditure.lines.reduce((total: number, line: Model.FiscalReportLine) => total + (line.amount || 0), 0);
          });
          this.closeContext.remaining = this.closeContext.total_allocations - this.closeContext.total_expenditures;
        }, Utilities.setCallbackError(this.store, this.constructor.name));
    }

    if (!requester) {
      this.apiService.getProfileById(this.closeTask.client_state.requester).subscribe(user => {
        this.closeContext.requester = user;
      })
    }
  }

  public allowApproval(users: Array<Model.User> = []) {
    return this.isSubmitted && this.canCertify && (!!users.find(u => this.currentUser.id === u.id));
  }

  public disableApproval(task: Model.Task) {
    return !task || (this.currentApprovalStep && (this.currentApprovalStep < task.client_state.step));
  }

  public openSubmitAlert() {
    if (!this.isValidWorkflow) {
      this.store.dispatch(Actions.App.showAlert('Not all steps have been completed. Fill out remaining information and try again.'));
    } else if (this.emitSubmit) {
      this.onSubmit.emit();
    } else {
      this.showSubmitAlert = true;
    }
  }

  public closeSubmitAlert() {
    this.showSubmitAlert = false;
  }

  public toggleUnsubmitAlert() {
    this.showUnsubmitAlert = !this.showUnsubmitAlert;
  }

  public openRejectModal(task: Model.Task) {
    this.taskToReject = task;
    this.rejectProposalForm.get('comments').setValue('');
    this.showRejectModal = true;
  }

  public closeRejectModal() {
    this.taskToReject = undefined;
    this.rejectProposalForm.get('comments').setValue('');
    this.showRejectModal = false;
  }

  public submit() {
    this.closeSubmitAlert();

    this.store.dispatch(Actions.CurrentProposal
      .completeTaskAndRefresh({
        task_type: this.planSubmitTaskType,
        proposal_id: this.proposal.id,
        duration_id: this.taskDurationId
      }, true));
  }

  public approve(task: Model.Task) {
    if (!this.emitApproval) {
      this.store.dispatch(Actions.CurrentProposal
        .completeTaskAndRefresh({
          task_type: task.task_type,
          proposal_id: this.proposal.id,
          duration_id: this.taskDurationId,
          institution_id: this.getApproverInstitutionId(task),
          role_id: task.role_id,
        }));
    } else {
      this.onApproval.emit(task);
    }
  }

  public reject() {
    const comments = this.rejectProposalForm.get('comments').value || undefined;

    this.store.dispatch(Actions.CurrentProposal
      .completeTaskAndRefresh({
        task_type: this.taskToReject.task_type,
        proposal_id: this.proposal.id,
        duration_id: this.taskDurationId,

        institution_id: this.getApproverInstitutionId(this.taskToReject),
        role_id: this.taskToReject.role_id,
        comments,
        rejected: true
      }));

    this.closeRejectModal();
  }

  public unsubmit() {
    this.showUnsubmitAlert = false;
    this.store.dispatch(Actions.CurrentProposal
      .undoTask({
        task_type: this.planSubmitTaskType,
        proposal_id: this.proposal.id,
        duration_id: this.taskDurationId
      }, true));
  }

  public closeClosureModal() {
    this.showCloseProposalModal = false;
  }

  public allowCloseApproval(users: Array<Model.User> = []) {
    return this.canCertify && (!!users.find(u => this.currentUser.id === u.id));
  }

  public allowCloseRejectOnly(users: Array<Model.User> = []) {
    return this.canCertify && (!!users.find(u => this.currentUser.id === u.id)) && !this.closeContext.reporting_approved;
  }

  public openCloseApproveAlert(task: Model.Task) {
    if (this.isLegacyClosure) {
      this.taskToApproveClosure = task;
      this.showCloseApproveAlert = true;
    } else {
      this.closeApprove(task);
    }
  }

  public closeApprove(task: Model.Task) {
    this.store.dispatch(Actions.CurrentProposal
      .completeTaskAndRefresh({
        task_type: task.task_type,
        proposal_id: this.proposal.id,
        duration_id: this.taskDurationId,
        institution_id: [PROGRAM_KEYS.SWP_L, PROGRAM_KEYS.SWP_R].includes(this.parentProgram.key) ? this.proposal.approver_institution_id : undefined,
        role_id: task.role_id,
      }, true));
  }

  public toggleReopenAlert() {
    this.showReopenAlert = !this.showReopenAlert;
  }

  public reopen() {
    this.showReopenAlert = false;
    this.store.dispatch(Actions.CurrentProposal
      .undoTask({
        task_type: this.planCloseSubmitTaskType,
        proposal_id: this.proposal.id,
        duration_id: this.taskDurationId
      }, true));
  }

  private get taskDurationId() {
    return this.durationId || this.proposal.duration_id;
  }

  getApproverInstitutionId(task) {
    if ([PROGRAM_KEYS.SWP_L, PROGRAM_KEYS.SWP_R].includes(this.parentProgram.key)) {
      return this.proposal.approver_institution_id;
    } else if ([PROGRAM_KEYS.CAEP].includes(this.programKey)) {
      return task.institution_id;
    }
  }
}
