import { Component, Input } from '@angular/core';

@Component({
  selector: 'po-badge',
  templateUrl: './badge.component.html',
})
export class BadgeComponent {
  @Input() iconId: string;
  @Input() type: 'error' | 'warning' | 'success' | 'info' = 'info';
  @Input() hasPopup: boolean;
  @Input() labelText: string;
  @Input() bold: boolean = true;
  @Input() pill: boolean;

  constructor() {}
}
