import { Component, Input, OnChanges, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Actions, Model, State } from '@app-ngrx-domains';
import { Store } from '@ngrx/store';
import { METRIC_GROUPS, METRIC_GROUPS_LABELS, PROGRAM_KEYS } from '@app/core/consts';
import { LookupService } from '@app/core/services';
import { groupBy, isNil } from 'lodash';
import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { PercentTransform } from '@app/shared.generic/pipes';
import { REQUIRED_3YP_METRIC_IDS } from '@app/AEBG/consts';

@Component({
  selector: 'project-targets-actuals-table',
  templateUrl: './targets-actuals-table.component.html',
})
export class TargetsActualsTableComponent implements OnInit, OnChanges {

  @Input() canEdit = false;
  @Input() firstTouch = true;
  @Input() isPreview = false;
  @Input() showCard = true;
  @Input() title: string;
  @Input() headingLevel: string = 'h3';
  @Input() helpText: string;
  @Input() footerText: string;
  @Input() proposal: any;
  @Input() metricsDefs: Array<any>;
  @Input() actualsDurations: Array<Model.Duration>;
  @Input() lbActuals: Array<Model.LBMetric>;
  @Input() swpActuals: Array<Model.LBMetric>;
  @Input() caepActuals: Array<Model.LBMetric>;
  @Input() planLength: number;
  @Input() targetInstitutionId: number;
  @Input() threeYearPlan: Model.EAThreeYearPlan;
  @Input() columnPrefix: string;

  @Input() set selectedMetricsIds(value: Array<number>) {
    this._selectedMetricsIds = value;
  };
  get selectedMetricsIds() {
    return this._selectedMetricsIds;
  }
  private _selectedMetricsIds: Array<number>;
  @Input() selectedMetricsIdsNoDelete: Array<number> = [];

  @ViewChild('tableHeaderTmpl', {static: true}) tableHeaderTmpl: TemplateRef<any>;
  @ViewChild('inputTmpl', {static: true}) inputTmpl: TemplateRef<any>;
  @ViewChild('valueTmpl', {static: true}) valueTmpl: TemplateRef<any>;

  public percentsForm: FormGroup;
  public columns: Array<{
    name: string;
    cellTemplate: TemplateRef<any>;
    cellCssClass?: string;
    headerCssClass?: string;
  }> = [];
  public rows: Array<{
    id: string;
    metricSet: string;
    metric: { id: number; name: string; }
    cellCssClass?: string;
    units: string;
    values: Array<{ id: string, value: number; isTarget: boolean; durationId: number; }>;
  }> = [];
  public units = {
    ['number']: {
      transform: 'number',
      pipeArgs: ['1.0'],
      pipe: this.decimalPipe,
      decimalPlaces: undefined,
      symbol: ''
    },
    ['dollars']: {
      transform: 'currency',
      pipeArgs: ['USD', 'symbol', '1.0-0'],
      pipe: this.currencyPipe,
      decimalPlaces: undefined,
      symbol: '$'
    },
    ['percent']: {
      transform: 'percent',
      pipeArgs: ['1.0-1'],
      pipe: this.percentPipe,
      decimalPlaces: 1,
      symbol: '%'
    },
    ['percent change']: {
      transform: 'percent',
      pipeArgs: ['1.0-1'],
      pipe: this.percentPipe,
      decimalPlaces: 1,
      symbol: '%'
    },
  };

  private year: number;
  private targetGoals: Array<Model.EATargetGoal> = [];
  private parentEffortAreas: Array<Model.EffortArea>;

  constructor(
    private _fb: FormBuilder,
    private lookupService: LookupService,
    private store: Store<State>,
    private currencyPipe: CurrencyPipe,
    private decimalPipe: DecimalPipe,
    private percentPipe: PercentTransform,
  ) { }

  ngOnInit(): void {
    this.percentsForm = this._fb.group({});
    this.buildMetricsDataTable();
  }

  ngOnChanges() {
    if (this.columns.length) {
      this.buildMetricsDataTable();
    }
  }

  private buildMetricsDataTable() {
    if (this.threeYearPlan) {
      this.year = this.threeYearPlan.duration_id;
      this.targetGoals = this.threeYearPlan.target_goals || [];
      this.parentEffortAreas = [this.threeYearPlan];
    } else {
      this.year = this.proposal.duration_id;
      this.targetGoals = this.proposal.target_goals;
    }

    const columns: Array<{
      name: string;
      cellTemplate: TemplateRef<any>;
      cellCssClass?: string;
      headerCssClass?: string;
    }> = [];

    // Add "Actuals" columns for previous years
    for (let year = 0; year <= this.actualsDurations.length - 1; year++) {
      const duration = this.actualsDurations[year].id;
      columns.push({
        name: this.lookupService.getYear(duration).name,
        cellTemplate: this.tableHeaderTmpl,
        headerCssClass: 'col-6-rem align-right',
        cellCssClass: 'align-right'
      });
    }

    // Add "Target" columns
    for (let year = this.year; year < this.year + this.planLength; year++) {
      columns.push({
        name: this.lookupService.getYear(year).name,
        cellTemplate: this.tableHeaderTmpl,
        headerCssClass: 'col-6-rem align-right',
        cellCssClass: 'align-right'
      });
    }

    // Initialize Targets
    const rows = [];
    let selectedMetricsIds = this._selectedMetricsIds;

    // go ahead and delete metrics that no longer exist
    this.deleteObsoleteTargets(selectedMetricsIds);

    const metricsByYear = {
      [METRIC_GROUPS.DEFAULT]: this.lbActuals ? groupBy(this.lbActuals, 'duration_id') : undefined,
      [METRIC_GROUPS.SWP]: this.swpActuals ? groupBy(this.swpActuals, 'duration_id') : undefined,
      [METRIC_GROUPS.CAEP]: this.caepActuals ? groupBy(this.caepActuals, 'duration_id') : undefined,
      [METRIC_GROUPS.CAEP_v2]: this.caepActuals ? groupBy(this.caepActuals, 'duration_id') : undefined,
      [METRIC_GROUPS.CAEP_PROGRAMS_v2]: this.caepActuals ? groupBy(this.caepActuals, 'duration_id') : undefined,
    };

    Object.keys(metricsByYear).forEach(metricSet => {
      if (!metricsByYear[metricSet]) {
        return;
      }
      // Get the selected metrics that belong to this metric set
      const selectedDefs = selectedMetricsIds.map(id => {
        return this.metricsDefs.find(d => d.id === id);
      }).filter(d => d && (d.group === metricSet));
      const rowsForSet = selectedDefs.map(def => {
        const row = {
          id: def.id,
          metricSet: [PROGRAM_KEYS.CAEP, METRIC_GROUPS.CAEP_v2, METRIC_GROUPS.CAEP_PROGRAMS_v2].includes(def.group) ? def.area : METRIC_GROUPS_LABELS[def.group],
          metric: { id: def.id, name: def.name },
          cellCssClass: 'align-right',
          units: def.units,
          values: []
        };
        const actualsDurationIds = this.actualsDurations.map(yearObj => yearObj.id);
        actualsDurationIds.forEach(durationId => {
          const metric = metricsByYear[metricSet][durationId]
            ? metricsByYear[metricSet][durationId].find(item => {
                // Need to match the institution for 3YP as it displays targets for Consortia & Members
                const matchesInstitution = this.threeYearPlan ? item.institution_id === this.targetInstitutionId : true;
                return item.metric_definition_id === def.id && matchesInstitution;
              })
            : undefined;
          const value = this.getActuals(metric, durationId, def.units);
          row.values.push({
            id: `cell_${def.id}_${this.targetInstitutionId}_${durationId}`, value, durationId, isTarget: false
          });
        });
        return row;
      }).sort((a, b) => a.metricSet.localeCompare(b.metricSet));

      rows.push(...rowsForSet);
    });

    // Initialize Target inputs
    const controls = {};
    rows.forEach((row) => {
      for (let year = this.year; year < this.year + this.planLength; year++) {
        const durationId = this.lookupService.getYear(year).id;
        const value = this.getTargetAmount(row.metric.id, durationId);
        const id = `cell_${row.metric.id}_${this.targetInstitutionId}_${durationId}`;
        row.values.push({
          id, value, durationId, isTarget: true
        });
        controls[id] = [value, Validators.required];
      }
    });

    if (!this.percentsForm) {
      this.percentsForm = this._fb.group(controls);
    } else {
      // Remove stale formControls
      Object.keys(this.percentsForm.controls).forEach(formControl => {
        if (!controls[formControl]) {
          this.percentsForm.removeControl(formControl);
        }
      });

      // Add new formControls
      Object.entries(controls).forEach(([controlName, formControl]) => {
        if (!this.percentsForm.controls[controlName]) {
          this.percentsForm.addControl(controlName, this._fb.control(formControl[0], formControl[1]));
        }
      });
    }

    this.columns = columns;
    this.rows = rows;
  }

  getActuals(def: any, duration_id: number, metric_units: string): string {
    let actuals: number;

    if (!def || def.is_missing) {
      return undefined;
    }

    if (metric_units === 'percent') {
      if (!isNil(def.percent)) {
        const value = Number(def.percent * 100).toFixed(this.units[metric_units].decimalPlaces); // truncate to fixed decimal places
        actuals = Number(value);
      }
    } else if (metric_units === 'percent change') {
      if (!isNil(def.value)) {
        const value = Number(def.value * 100).toFixed(this.units[metric_units].decimalPlaces); // truncate to fixed decimal places
        actuals = Number(value);
      }
    } else if (metric_units === 'dollars') {
      if (!isNil(def.value)) {
        const value = Number(def.value).toFixed(this.units[metric_units].decimalPlaces); // truncate to fixed decimal places
        actuals = Number(value);
      }
    } else {
      actuals = def.value;
    }

    if (!isNil(actuals)) {
      const pipe = this.units[metric_units].pipe;
      const pipeArgs = this.units[metric_units].pipeArgs;
      return (pipeArgs)
        ? pipe.transform(actuals, ...pipeArgs)
        : pipe.transform(actuals);
    }
  }

  getTargetAmount(metric_definition_id: number, duration_id: number): number {
    const target = this.targetGoals.find(tg => {
      return tg.institution_id === this.targetInstitutionId
        && tg.duration_id === duration_id
        && tg.metric_definition_id === metric_definition_id;
    });
    return target ? target.target_amount : undefined;
  };

  private deleteObsoleteTargets(selectedMetricsIds: Array<number>) {
    if (!this.targetGoals.length) {
      // none's been entered
      return;
    }

    const deleteTargets = [];
    this.targetGoals.forEach(tg => {
      if (!selectedMetricsIds.includes(tg.metric_definition_id)) {
        if (this.selectedMetricsIdsNoDelete.includes(tg.metric_definition_id)) {
          return;
        }
        deleteTargets.push(tg);
      }
    });

    if (deleteTargets.length) {
      this.store.dispatch(Actions.CurrentProposal.deleteMultiEffortAreas({eas: deleteTargets, parentEffortAreas: this.parentEffortAreas }));
    }
  }

  public getIndicator(metricCode: string) {
    const showAsterisk = this.caepActuals ? [...REQUIRED_3YP_METRIC_IDS.consortia, ...REQUIRED_3YP_METRIC_IDS.member].includes(metricCode) : false;
    return showAsterisk ? '*' : '';
  }

  public persistTarget(id: string, metric_definition_id: number, cell: any) {
    const value = this.percentsForm.get(id).value;
    const ea = this.targetGoals.find(tg => {
      return tg.institution_id === this.targetInstitutionId
        && tg.duration_id === cell.durationId
        && tg.metric_definition_id === metric_definition_id;
    });

    if (ea && value !== ea.target_amount) {
      this.store.dispatch(Actions.CurrentProposal.upsertAttribute({ key: 'target_amount', value, ea, parentEffortAreas: this.parentEffortAreas }));
    } else {
      this.store.dispatch(Actions.CurrentProposal.createEffortArea({
        ea: {
          effort_area_type: 'target_goals',
          parent_effort_area_id: this.threeYearPlan ? this.threeYearPlan.id : undefined,
          parent_proposal_id: this.proposal.id,
          institution_id: this.targetInstitutionId,
          duration_id: cell.durationId,
          metric_definition_id,
          target_amount: value,
        },
        parentEffortAreas: this.parentEffortAreas
      }));
    }
  }

  public trackByColumn(index: number, column) {
    return column.name;
  }

  public trackById(index: number, item: any) {
    return item.id;
  }
}
