import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Actions, State } from '@app-ngrx-domains';
import { Utilities } from '@app/core/models';
import { CHAR_LIMITS } from '@app/core/consts';

@Component({
  selector: 'close-project-modal',
  templateUrl: './close-project-modal.component.html'
})
export class CloseProjectModalComponent implements OnInit {
  @Input() programKey: string;
  @Input() projectBaseYear: number;
  @Input() projectLength: number;
  @Output() close: EventEmitter<any> = new EventEmitter();

  closeProposalForm: FormGroup;

  require_funds_text = false;
  word_project: string = 'plan';
  word_Project: string = 'Plan';

  reportingStartDate: string;
  reportingEndDate: string;
  reportingStartDateFormatted: string;
  reportingEndDateFormatted: string;

  constructor(
    private fb: FormBuilder,
    private store: Store<State>) {
  }

  ngOnInit() {
    this.reportingStartDate = `${this.projectBaseYear - 1}-07-01`;
    this.reportingStartDateFormatted = Utilities.formatDate(this.reportingStartDate);
    this.reportingEndDate = `${this.projectBaseYear + this.projectLength - 1}-06-30`;
    this.reportingEndDateFormatted = Utilities.formatDate(this.reportingEndDate);

    this.closeProposalForm = this.fb.group({
      close_date: [null, [Validators.required]],
      plan_completed: [true],
      funds_expended: [true],
      funds_outcomes: [''],
      project_outcomes: ['', [Validators.required, Validators.minLength(CHAR_LIMITS.NARRATIVE_MIN)]]
    });

    this.closeProposalForm
      .get('funds_expended')
      .valueChanges
      .subscribe(value => {
        this.require_funds_text = !value;
        const validator = !value ? [Validators.required, Validators.minLength(CHAR_LIMITS.NARRATIVE_MIN)] : null;
        this.closeProposalForm.get('funds_outcomes').setValidators(validator);
        this.closeProposalForm.get('funds_outcomes').updateValueAndValidity();
      });
  }

  submitCloseProposalForm() {
    let {close_date, plan_completed, funds_expended, project_outcomes, funds_outcomes} = this.closeProposalForm.value;
    funds_outcomes = funds_expended ? '' : funds_outcomes;
    const close_reason = (plan_completed ? `${this.word_Project} completed` : `${this.word_Project} not completed, not pursued`) +
      '; ' + (funds_expended ? 'funding fully expended' : 'funding not fully expended');

    this.store.dispatch(Actions.CurrentProposal.close({
      close_date: Utilities.stripTime(close_date),
      close_reason,
      plan_completed,
      funds_expended,
      project_outcomes,
      funds_outcomes
    }));
    this.close.emit();
  }

  cancelCloseProposalForm() {
    this.closeProposalForm.get('project_outcomes').setValue('');
    this.closeProposalForm.get('close_date').setValue('');
    this.closeProposalForm.get('plan_completed').setValue(true);
    this.closeProposalForm.get('funds_expended').setValue(true);
    this.close.emit();
  }
}
